import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as RegaloIcon } from '../../assets/icons/programas/strength/icon-bono01.svg';
import bonoExtra1 from '../../assets/icons/programas/strength/icon-bono02.svg';
import bonoExtra2 from '../../assets/icons/programas/strength/icon-bono03.svg';
import bonoExtra3 from '../../assets/icons/programas/strength/icon-bono04.svg';
import bonoExtra4 from '../../assets/icons/programas/strength/icon-bono05.svg';
import HeaderNav from '../../components/Header';
import Footer from '../../components/Footer';
import SEO from '../../components/SEO';
import { usePromocion } from '../../components/PromocionProvider';
import VideoPreviewPlayer from '../../components/VideoPreviewPlayer';
import { getBannerPrograma, getProgramas } from '../../api/client/programas';
import { useCustomState } from '../../hooks/useCustomState';
import intersectionObserver from '../../utils/intersectionObserver';
import PreguntasFrecuentes from '../components/PreguntasFrecuentes';
import EntrenaApp from './components/EntrenaApp';
import Membresias from './components/Membresias';
import BenefitsGrid from './components/BenefitsGrid';
import DisfrutaEnDispositivos from './components/DisfrutaEnDispositivos';
import TestimoniosProgramasCarousel from './components/TestimoniosProgramasCarousel';
import BeneficiosButtonsCarousel from '../components/BeneficiosButtonsCarousel';
import BeneficiosProgramasCarousel from './components/BeneficiosProgramasCarousel';
import { benefitsStrengthData } from '../../misc/getBenefitsProgramData';
import { getPreguntasFrecuentesStrength } from '../../misc/getPreguntasFrecuentes';
import { setSubscription } from '../../redux/reducers/subscriptionSlice';

const ProgramaStrength = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carouselBeneficiosRef = React.createRef();
  const carouselTestimoniosRef = React.createRef();
  const elementsRef = useRef([]);

  const { state: statePromocion } = usePromocion();
  const [state, setState] = useCustomState({
    preguntasFrecuentes: [],
    isVideoVisible: false,
    portada_video_introduccion: null,
    video_introduccion: null,
    programaDetalles: null,
    bannerDetalles: null,
  });

  useEffect(() => {
    searchSuscripcionData();
    loadProgram();

    (async () => {
      setState({
        preguntasFrecuentes: await getPreguntasFrecuentesStrength(),
      });
    })();

    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  const searchSuscripcionData = useCallback(async () => {
    // const _getVimeoAPI = async (id) => (await getDataVimeoApi(id))?.data;

    setState({
      portada_video_introduccion: "/assets/images/strength_fitmom.jpg", // (await _getVimeoAPI(821854458))?.video?.thumbs?.base,
      video_introduccion: {
        url: "/assets/videos/strength_fitmom.mp4",
      },
    });
  }, [setState]);

  const loadProgram = useCallback(async () => {
    try {
      const programasResponse = await getProgramas({ code: name });
      const programDetails = programasResponse?.data?.[0];

      let bannerResponse = null;
      if (programDetails?.id) {
        try {
          bannerResponse = await getBannerPrograma({ programID: programDetails.id });
        } catch (bannerError) { }
      }

      setState({
        programaDetalles: programDetails,
        bannerDetalles: bannerResponse?.data || { image: require('../../assets/images/programas/banners/banner-avanzado.jpg') },
      });
    } catch (error) {
      setState({
        bannerDetalles: { image: require('../../assets/images/programas/banners/banner-avanzado.jpg') },
      });
    }
  }, [name, setState]);

  const seleccionarSuscripcion = useCallback((plan) => {
    dispatch(setSubscription({
      data: {
        id: state.programaDetalles?.id,
        tipo_programa: "Programa de entrenamiento",
        code: state.programaDetalles?.code,
        nombre: state.programaDetalles?.name,
        plan: plan,
        precio: plan === "mensual" ? state.programaDetalles?.cost_month : state.programaDetalles?.cost_biannual,
      }
    }));
    navigate(`/carrito/strength-${plan}`);
  }, [state.programaDetalles, dispatch, navigate]);

  return (
    <>
      <SEO
        title="Programa Avanzado"
        description="Para mujeres que han completado por lo menos 6 meses del programa posparto y están listas para aumentar la intensidad."
      />
      {/* Formato de imagenes */}
      {/* <div
        className={`bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center transition-all ${statePromocion.isActive ? "mt-[3.1rem]" : ""}`}
        style={{
          ...(state.bannerDetalles?.image ? { backgroundImage: `url(${state.bannerDetalles.image})` } : {}),
          paddingTop: "calc(720 / 1920 * 100%)"
        }}
      >
        <HeaderNav />
      </div> */}

      {/* Formato de videos */}
      <div
        className={`relative overflow-hidden bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center transition-all ${statePromocion.isActive ? "mt-[3.1rem]" : ""}`}
        style={{
          paddingTop: "calc(1080 / 1920 * 100%)"
        }}
      >
        <VideoPreviewPlayer
          playerContainerClassName="absolute top-0 left-0 w-full h-full pointer-events-none z-1"
          hasOverlay={false}
          autoplay={true}
          loop={true}
          controls={false}
          altText='Video strength'
          videoSrc={require("../../assets/videos/programas/VIDEO-Strength.mp4")}
        />
        <HeaderNav />
      </div>

      <div
        className='bg-LavenderBlush bg-left lg:bg-center bg-cover bg-no-repeat w-full'
        style={{
          backgroundImage: `url("${require("../../assets/images/bg_degraded/4.jpg")}")`
        }}
      >
        <div className="xl:container mx-auto px-6 max-w-6xl">
          <div className='grid sm:grid-cols-2 gap-4'>
            <div className='order-2 sm:order-1 max-w-[520px] mx-auto pt-[2rem] sm:pt-[4.5rem] sm:pb-[4.5rem] pb-[2rem] text-center sm:text-left'>
              <h1
                ref={(el) => elementsRef.current.push(el)}
                className='animate__animated font-golos-semibold text-xl lg:text-2xl text-Crayola'
                data-animation={"animate__fadeInDown"}
              >
                Programa Avanzado
              </h1>
              <h1
                ref={(el) => elementsRef.current.push(el)}
                className='animate__animated font-bebas text-4xl md:text-5xl lg:text-7xl text-DeepKoamaru'
                data-animation={"animate__fadeInUp"}
              >
                FITMOM STRENGTH
              </h1>
              <p
                className='font-golos-medium text-md lg:text-md text-DeepKoamaru mt-2'
              >
                Para mujeres que han completado 6 meses del programa posparto y están listas para aumentar la intensidad.
              </p>
            </div>
            <div className='order-1 sm:order-2 relative'>
              <div className='-my-[2rem] sm:my-0 sm:absolute sm:top-0 sm:translate-y-[-45%] group-hover:translate-y-0 group-hover:my-0 group-hover:relative transition-transform duration-300'>
                <LazyLoadImage
                  alt="App image"
                  src={require("../../assets/images/programas/img-app-strength.png")}
                  className='mx-auto max-w-[220px] sm:mx-none xs:max-w-[300px] sm:max-w-[420px] w-full'
                />
              </div>
            </div>
          </div>
          <div className='mt-8 mb-[4.5rem] mx-auto max-w-[1145px] text-center sm:text-left'>
            <div className='max-w-[680px]'>
              <p
                className='font-golos-medium text-sm lg:text-md text-DeepKoamaru sm:whitespace-break-spaces'
              >
                <span className='font-golos-semibold text-Crayola'>IMPORTANTE:  </span>
                ESTE PROGRAMA NO ES APTO PARA PRINCIPIANTES O MUJERES CON DISFUNCIONES COMO DIÁSTASIS, INCONTINENCIA, PROLAPSO U OTROS PROBLEMAS DEL CORE.
              </p>
            </div>
            <p
              className='font-golos-bold text-sm lg:text-md text-text mt-4'
            >
              Si tienes cualquiera de estos síntomas debes inscribirte al programa <Link to="/programas/fitmom-posparto" className='font-golos-semibold text-Crayola hover:underline'>POSPARTO.</Link>
            </p>
          </div>
        </div>
      </div>

      <div className='bg-white pt-6 md:pt-16 pb-16 w-full'>
        <div className='xl:container mx-auto px-6 max-w-6xl'>
          <h1
            ref={(el) => elementsRef.current.push(el)}
            className='animate__animated font-bebas text-3xl md:text-4xl lg:text-5xl text-center text-DeepKoamaru mb-10'
            data-animation={"animate__fadeInDown"}
          >
            CON ESTE PROGRAMA PODRÁS:
          </h1>
          <BenefitsGrid
            benefits={benefitsStrengthData}
            program="strength"
          />
          <div className='mt-8 xs:px-6 sm:px-12'>
            <VideoPreviewPlayer
              hasOverlay={false}
              playerContainerClassName='rounded-2xl'
              altText='Thumbnail video strength'
              thumbnail={require("../../assets/images/programas/video-strength.jpg")}
              videoSrc={require("../../assets/videos/video-strength.mp4")}
            />
          </div>
        </div>
      </div>

      <div
        className='bg-LavenderBlush w-full overflow-x-hidden'
      >
        <div className="xl:container mx-auto px-6">
          <div className='max-w-[760px] mx-auto'>
            <div ref={(el) => elementsRef.current.push(el)} className='animate__animated py-10 sm:py-16' data-animation={"animate__fadeInLeft"}>
              <h1 className='leading-7 font-golos-bold text-DeepKoamaru text-center text-lg sm:text-xl md:text-2xl md:leading-7 sm:whitespace-break-spaces'>
                {`¿Listas para llevar tu \nrecuperación al siguiente nivel?`}
              </h1>
              <div className='flex mx-auto max-w-xl sm:-my-5'>
                <LazyLoadImage
                  alt="cover image"
                  src={require("../../assets/images/programas/img-jimefit-3.png")}
                  className='object-contain w-full h-full'
                />
              </div>
              <div className='flex flex-col gap-6 mb-8'>
                <div className='max-w-[720px] mx-auto'>
                  <p className='font-golos leading-5 text-sm sm:text-md text-DeepKoamaru text-center'>
                    Si ya has completado el programa posparto y sientes que tu cuerpo te exige un nivel más, FitMom Strength es esa fase en tu camino. Has trabajado en la rehabilitación y ahora es el momento de seguir fortaleciendo tu core, mejorar tu postura, y aumentar tu resistencia física. ¿Te gustaría tener más resistencia?
                  </p>
                </div>
                <div className='max-w-[720px] mx-auto'>
                  <p className='font-golos leading-5 text-sm sm:text-md text-DeepKoamaru text-center'>
                    Imagina un cuerpo que no solo se siente libre de dolor, sino que está más fuerte que nunca. Este programa es ideal para mujeres que ya han recuperado su base, pero ahora quieren desafiarse y seguir creciendo.
                  </p>
                </div>
              </div>
              <div className='flex justify-center'>
                <a href="#programas" className='inline-flex font-golos-semibold bg-DeepKoamaru text-white rounded-full py-4 px-5 no-underline text-sm text-center'>
                  PRUEBA AHORA MIS 7 DÍAS GRATIS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Membresias
        typeProgram={state.programaDetalles?.code}
        onSubscribe={seleccionarSuscripcion}
        options={{
          price_month: state.programaDetalles?.cost_month,
          price_biannual: state.programaDetalles?.cost_biannual,
        }}
      />

      <EntrenaApp program={"strength"} />

      <div className='pt-10 pb-16 bg-[#eae9f7] overflow-hidden'>
        <div className="xl:container mx-auto px-10">
          <div
            ref={(el) => elementsRef.current.push(el)}
            data-animation={"animate__fadeInDown"}
            className='animate__animated flex flex-col sm:flex-row items-center gap-6 mt-10 mb-10'
          >
            <div className='flex-1'>
              <h1 className='max-w-[520px] font-golos-semibold text-DeepKoamaru text-center sm:text-left text-2xl whitespace-break-spaces'>
                {`Esto es lo que incluye tu suscripción \nal programa de ejercicio Fitmom Strength.`}
              </h1>
            </div>
            <BeneficiosButtonsCarousel carouselRef={carouselBeneficiosRef} />
          </div>
          <BeneficiosProgramasCarousel
            customRef={carouselBeneficiosRef}
            program="strength"
          />
          <div className='mb-8 mt-14 sm:mt-20'>
            <div className='flex flex-col md:flex-row items-center justify-center gap-4'>
              <RegaloIcon height={48} width={48} />
              <h1 className='font-bebas text-DeepKoamaru text-center text-4xl md:text-5xl lg:text-6xl'>
                ADEMÁS LLÉVATE ESTOS BONOS EXTRA:
              </h1>
            </div>
            <div className='mt-8 sm:mt-16 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-10 sm:gap-12 max-w-[600px] w-full mx-auto'>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra1}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Guía nutricional para \nreducir inflamación`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra2}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Guía para \nconstruir hábitos`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra3}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Tarjetas digitales \nde afirmaciones`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra4}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Descuentos \ncon partners`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='bg-LavenderBlush bg-left sm:bg-center bg-cover sm:bg-[size:100%_100%] bg-no-repeat w-full'
        style={{
          backgroundImage: `url("${require("../../assets/images/bg_degraded/6.jpg")}")`
        }}
      >
        <div className="xl:container mx-auto px-6 max-w-6xl">
          <div className='grid grid-cols-1 sm:grid-cols-[1.1fr_1.6fr] items-center gap-10'>
            <div className='mx-auto max-w-[260px] sm:max-w-[360px]'>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/img-jimecert.png")}
              />
            </div>
            <div className='sm:py-8 mb-10 sm:mb-0 space-y-7 text-center sm:text-left max-w-[610px]'>
              <h3 className='font-bebas text-3xl sm:text-5xl text-DeepKoamaru'>JIMENA CANTÚ</h3>
              <p className='font-golos-bold text-md'>
                Soy mamá de 3 niñas, Fitness Coach Certificada en Ejercicio Pre y Posnatal, Especialista en Ejercicios Correctivos, Suelo Pélvico y Core por Fit For Birth y AFPA.
              </p>
              <p className='font-golos text-md'>
                Con mis programas de entrenamiento he podido ayudar a más de 15,000 mamás a tener embarazos y pospartos más saludables, mejorando su experiencia de parto y recuperación. Mi misión es educar y empoderar a las mamás, proporcionándoles herramientas para que se sientan seguras y confiadas con su cuerpo y su maternidad.
              </p>
              <div className='mx-auto sm:mx-none max-w-[420px] pt-3'>
                <p className='font-golos-bold text-md text-Crayola'>
                  Recomendada por ginecólogos, doulas y fisioterapeutas en Estados Unidos, México y Latinoamérica.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-12 bg-[#eae9f7]'>
        <div className="xl:container mx-auto px-6 mb-12 overflow-x-hidden">
          <div
            ref={(el) => elementsRef.current.push(el)}
            data-animation={"animate__fadeInDown"}
            className='animate__animated flex flex-row items-center gap-6 mt-10 mb-12 md:mr-16'
          >
            <div className='flex-1'>
              <h1 className='max-w-[520px] font-golos-bold text-DeepKoamaru text-left text-2xl'>
                Testimonios de clientes felices
              </h1>
            </div>
            <BeneficiosButtonsCarousel carouselRef={carouselTestimoniosRef} />
          </div>
          <TestimoniosProgramasCarousel
            customRef={carouselTestimoniosRef}
            program="strength"
          />
        </div>
      </div>

      <DisfrutaEnDispositivos program="strength" />

      <div className='py-16 bg-[#fbf5fa]'>
        <div className="xl:container mx-auto px-6">
          <div className='mt-4 mb-10'>
            <h1
              ref={(el) => elementsRef.current.push(el)}
              className='font-bebas text-DeepKoamaru text-center leading-10 text-[2.4rem] animate__animated mb-4 xs:mb-2'
              data-animation={"animate__slideInDown"}
            >
              PREGUNTAS FRECUENTES SOBRE EL PROGRAMA
            </h1>
            <p className='font-golos-medium text-Crayola text-lg text-center'>
              No te preocupes, nosotras te respondemos
            </p>
          </div>
          <div className='mx-auto max-w-[50rem]'>
            <PreguntasFrecuentes
              data={state.preguntasFrecuentes}
            />
          </div>
          <div className='flex items-center justify-center mt-12 mb-5'>
            <div className='bg-Crayola py-3.5 px-12 rounded-full inline-flex'>
              <p className='font-golos-semibold leading-7 text-AntiFlashWhite text-center'>
                ¿TE QUEDARON DUDAS?, ESCRIBE A <a href='mailto:hola@jimefitmom.com' className='font-golos-semibold text-none'>HOLA@JIMEFITMOM.COM</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProgramaStrength;