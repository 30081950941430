import React, { memo, useRef } from "react";
import classNames from "classnames";
import { ReactComponent as DropDownIcon } from "../../assets/icons/btn-abrir.svg";
import { ReactComponent as DropUpIcon } from "../../assets/icons/btn-cerrar.svg";
import { useCustomState } from "../../hooks/useCustomState";

import { useSpring, animated } from "@react-spring/web";

const RenderPregunta = memo(({ item }) => {
  const [state, setState] = useCustomState({
    isVisibleContent: false,
  });

  const ref = useRef(null);

  function handleDescriptionToggle() {
    setState({ isVisibleContent: !state.isVisibleContent });
  }

  const spring = useSpring({
    to: {
      height: state.isVisibleContent ? ref.current.scrollHeight : 0,
    },
    config: { duration: 200 },
  });

  const descriptionClasses = classNames({
    "text-DarkJungleGreen": true,
    "text-sm": true,
    "mt-2": state.isVisibleContent,
    "overflow-hidden": !state.isVisibleContent,
    "animate__animated": true,
    "animate__fadeIn": state.isVisibleContent,
    "animate__faster": true
  });

  return (
    <div className="flex items-start gap-4">
      <div
        className={`c-pointer bg-white pl-6 pr-5 ${state.isVisibleContent ? "rounded-3xl py-4" : "rounded-2xl xs:rounded-full py-3"} flex-1 overflow-hidden transition-all ease-in-out`}
        onClick={handleDescriptionToggle}
        style={{ height: "auto" }}
      >
        <div className="flex flex-row items-center justify-between gap-6">
          <div className="flex-1 xs:pl-3">
            <p className="font-golos-medium leading-5 text-DeepKoamaru text-md text-left">
              {item?.title}
            </p>
            <animated.div className={descriptionClasses} style={spring}>
              <div className="text-DeepKoamaru whitespace-pre-line" ref={ref}>
                {item?.description}
              </div>
            </animated.div>
          </div>
          <div className="c-pointer active:scale-90 transition ease-in-out">
            {!state.isVisibleContent ? <DropDownIcon height={16} width={16} /> : <DropUpIcon height={16} width={16} />}
          </div>
        </div>
      </div>
    </div>
  );
});


const PreguntasFrecuentes = ({ data }) => (
  <div className='grid gap-4'>
    {data?.length > 0 && data?.map((item, index) => (
      <RenderPregunta key={`pregunta-frecuente-key-${item?.id || index}`} item={item} />
    ))}
  </div>
);

export default PreguntasFrecuentes;