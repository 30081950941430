import { memo, useMemo } from "react";
import { ReactComponent as Phone } from "../../../assets/icons/01device-phone.svg";
import { ReactComponent as Tablet } from "../../../assets/icons/02device-tablet.svg";
import { ReactComponent as Web } from "../../../assets/icons/03device-pc.svg";
import { ReactComponent as Tv } from "../../../assets/icons/04device-tv.svg";

const programStyles = {
  "embarazo": {
    bgContainer: "bg-Crayola",
  },
  "posparto": {
    bgContainer: "bg-OrchidHover",
  },
  "strength": {
    bgContainer: "bg-DeepKoamaru",
  },
};

const DisfrutaEnDispositivos = ({ program }) => {
  const { bgContainer } = useMemo(() => programStyles[program] || {}, [program]);

  return (
    <div className={`py-8 sm:py-12 md:py-16 ${bgContainer}`}>
      <div className="xl:container mx-auto px-6">
        <div className="max-w-4xl mx-auto sm:px-6">
          <div className='mb-10'>
            <p className='font-golos-semibold leading-7 text-white text-[1.30rem] sm:text-2xl text-center'>
              Entrena y disfruta a Jimefitmom
            </p>
            <p className='font-golos-semibold leading-7 text-white text-[1.30rem] sm:text-2xl text-center'>
              en tus dispositivos favoritos
            </p>
          </div>
          <div className="grid grid-cols-1 xxs:grid-cols-2 md:grid-cols-4 items-center justify-center gap-8">
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="flex items-center h-[80px]">
                <Phone width={46} />
              </div>
              <p className="font-golos text-AntiFlashWhite text-lg text-center">iOS, Android</p>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="flex items-center h-[80px]">
                <Tablet width={112} />
              </div>
              <p className="font-golos text-AntiFlashWhite text-lg text-center">iPad OS, Android</p>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="flex items-center h-[80px]">
                <Web width={102} />
              </div>
              <p className="font-golos text-AntiFlashWhite text-lg text-center">Web</p>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="flex items-center h-[80px]">
                <Tv width={102} />
              </div>
              <p className="font-golos text-AntiFlashWhite text-lg text-center">TV</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(DisfrutaEnDispositivos);