import Icon1 from "../assets/icons/programas/embarazo/icon-benef01.svg";
import Icon2 from "../assets/icons/programas/embarazo/icon-benef02.svg";
import Icon3 from "../assets/icons/programas/embarazo/icon-benef03.svg";
import Icon4 from "../assets/icons/programas/embarazo/icon-benef04.svg";
import Icon5 from "../assets/icons/programas/embarazo/icon-benef05.svg";
import Icon8 from "../assets/icons/programas/embarazo/icon-benef08.svg";
import Icon9 from "../assets/icons/programas/embarazo/icon-benef09.svg";

import IconPos1 from "../assets/icons/programas/posparto/icon-benef01.svg";
import IconPos2 from "../assets/icons/programas/posparto/icon-benef02.svg";
import IconPos3 from "../assets/icons/programas/posparto/icon-benef03.svg";

import IconStr1 from "../assets/icons/programas/strength/icon-benef01.svg";
import IconStr2 from "../assets/icons/programas/strength/icon-benef02.svg";
import IconStr3 from "../assets/icons/programas/strength/icon-benef03.svg";
import IconStr4 from "../assets/icons/programas/strength/icon-benef04.svg";
import IconStr7 from "../assets/icons/programas/strength/icon-benef07.svg";
import IconStr8 from "../assets/icons/programas/strength/icon-benef08.svg";
import IconStr9 from "../assets/icons/programas/strength/icon-benef09.svg";

export const getCarouselBeneficiosProgramaEmbarazo = () => {
  return [
    {
      icon: Icon1,
      text: `Calendario semanal de ejercicios prenatales. \n200+ rutinas de ejercicios funcionales de fuerza, cardio, yoga, pilates y barre prenatal.`
    },
    {
      icon: Icon2,
      text: "Ejercicios para aliviar dolor de espalda baja, ejercicios para ciática, ejercicios para dolor pélvico y otros dolores comunes del embarazo."
    },
    {
      icon: Icon3,
      text: "Ejercicios de preparación para el parto, formas efectivas de pujo, rotar al bebé y más."
    },
    {
      icon: Icon4,
      text: "Ejercicios para embarazo en reposo."
    },
    {
      icon: Icon4,
      text: "Retos mensuales con enfoque en objetivos específicos."
    },
    {
      icon: Icon5,
      text: "Meditaciones y afirmaciones para el parto."
    },
    {
      icon: Icon8,
      text: "Acceso a recursos para apoyo emocional durante el embarazo."
    },
    {
      icon: Icon4,
      text: "Acceso a grupo privado de alumnas."
    },
    {
      icon: Icon9,
      text: "Acceso a la aplicación móvil."
    },
  ];
};

export const getCarouselBeneficiosProgramaPosparto = () => {
  return [
    {
      icon: IconPos1,
      text: `Ejercicios de recuperación después del parto o cesárea incluyendo ejercicios para fortalecer suelo pélvico y core.`
    },
    {
      icon: IconPos2,
      text: "Ejercicios para corregir diástasis, incontinencia y vientre abultado (mommy pooch)."
    },
    {
      icon: IconPos3,
      text: "Guía de rutinas semanales con ejercicios de fuerza, cardio, yoga, pilates y barre postnatales. 200+ entrenamientos disponibles."
    },
    {
      icon: Icon4,
      text: "Rutinas cortas de ejercicios y rutinas para hacer con tu bebé."
    },
    {
      icon: Icon4,
      text: "Retos mensuales con enfoque en objetivos específicos."
    },
    {
      icon: Icon4,
      text: "Meditaciones guiadas y recursos de apoyo emocional."
    },
    {
      icon: IconPos1,
      text: "Técnicas de masaje para cicatrices de cesárea, episiotomía o desgarro."
    },
    {
      icon: Icon4,
      text: "Ejercicios para aliviar dolor de espalda baja, ciática, dolores pélvicos y más."
    },
    {
      icon: Icon8,
      text: "Acceso a grupo privado de alumnas."
    },
    {
      icon: Icon9,
      text: "Acceso a la aplicación móvil."
    },
  ];
};

export const getCarouselBeneficiosProgramaStrength = () => {
  return [
    {
      icon: IconStr1,
      text: `Fundamentos del core y suelo pélvico para reforzar las bases.`
    },
    {
      icon: IconStr2,
      text: "Ejercicios avanzados para mejorar fuerza y rendimiento de suelo pélvico y core."
    },
    {
      icon: IconStr3,
      text: "Calendario semanal de entrenamientos avanzados de fuerza, yoga, pilates y barre."
    },
    {
      icon: IconStr4,
      text: "Ejercicios para aliviar dolor de espalda baja, cuello, ciática, dolor pélvico y más."
    },
    {
      icon: IconStr4,
      text: "Retos mensuales con enfoque en objetivos específicos."
    },
    {
      icon: IconStr4,
      text: "Meditaciones y recursos de apoyo emocional."
    },
    {
      icon: IconStr7,
      text: "Documentos descargables con información complementaria."
    },
    {
      icon: IconStr8,
      text: "Acceso a grupo privado de alumnas."
    },
    {
      icon: IconStr9,
      text: "Acceso a la aplicación móvil."
    },
  ];
};