import React, { useEffect, useMemo, useRef } from 'react';
import StoreApps from '../../../components/StoreApps';
import intersectionObserver from '../../../utils/intersectionObserver';

const programStyles = {
  "embarazo": {
    bgContainer: "bg-Crayola",
    image: require("../../../assets/images/programas/banner-embarazo-img.png"),
  },
  "posparto": {
    bgContainer: "bg-OrchidHover",
    image: require("../../../assets/images/programas/banner-posparto-img.png"),
  },
  "strength": {
    bgContainer: "bg-DeepKoamaru",
    image: require("../../../assets/images/programas/banner-strength-img.png"),
  },
};

const EntrenaApp = ({ program = "embarazo" }) => {
  const elementsRef = useRef([]);

  const { bgContainer, image } = useMemo(() => programStyles[program] || {}, [program]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className={`relative ${bgContainer}`}>
      <div className='xl:container mx-auto px-6 pt-4 pb-8 lg:pt-0 lg:pb-0'>
        <div className='flex flex-wrap gap-6 pt-0 justify-center items-center'>
          <div ref={(el) => elementsRef.current.push(el)} className='animate__animated' data-animation={"animate__fadeInLeft"}>
            <img
              src={image}
              alt="App Preview"
              className='max-w-xs lg:max-w-lg mx-auto select-none pointer-events-none'
              style={{ width: "95%" }}
            />
          </div>
          <div className='text-center lg:text-start'>
            <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInRight"} className='animate__animated max-w-[400px]'>
              <h2 className='text-xl sm:text-3xl lg:text-4xl text-AntiFlashWhite'>
                Entrena donde sea con el
                <span className='font-golos-bold'> app de ejercicios </span>
                más completa para
                <span className='font-golos-bold'> embarazo y posparto </span>
              </h2>
            </div>
            <div className='mt-6'>
              <StoreApps />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntrenaApp;