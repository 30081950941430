export const navItems = [
  {
    label: 'Programas', path: '/programas', subItems: [
      { label: 'Fitmom Embarazo', path: '/programas/fitmom-embarazo' },
      { label: 'Fitmom Posparto', path: '/programas/fitmom-posparto' },
      { label: 'Fitmom Strength', path: '/programas/fitmom-strength' },
    ]
  },
  { label: 'Manuales', path: 'https://jimefitmom.vip/manuales-para-un-embarazo-y-posparto-saludable' },
  { label: 'Sobre Jime', path: '/sobre-jime' },
  { label: 'Consultas', path: 'https://calendly.com/jimenacantu' },
  { label: 'Blog', path: '/blog' },
  { label: 'Newsletter', path: 'https://jimefitmom.vip/fitmom-connect', onlyMobile: false },
  { label: 'Partners', path: '/partners', onlyMobile: false },
];

const fillColors = {
  white: {
    default: "#7e73d6",
    active: "#fff"
  },
  black: {
    default: "#fff",
    active: "#000"
  }
};

export const fillSVG = (theme, state) => fillColors[theme || "black"][state.isClickedSubMenu || state.showSubMenu ? "active" : "default"];