import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import ReactPlayer from 'react-player/lazy';
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { ButtonPrimary } from '../components/Button';
import VideoPlayer from '../components/Modal/VideoPlayer';
import CarouselProgramasTemplate from './components/CarouselProgramasTemplate';
import EspecialistaCertificada from './components/EspecialistaCertificada';
import ScrollToAnchor from '../components/ScrollToAnchor';
import BeneficiosCarousel from './components/BeneficiosCarousel';
import ContenidoClases from './components/ContenidoClases';
import RazonesEntrenarJimeFitMom from './components/RazonesEntrenarJimeFitMom';
import TestimoniosClientas from './components/TestimoniosClientas';
import { useCustomState } from '../hooks/useCustomState';
import { getCarouselProgramasItems } from '../misc/getCarouselItems';
import intersectionObserver from '../utils/intersectionObserver';
import BeneficiosButtonsCarousel from './components/BeneficiosButtonsCarousel';

const Index = ({ onLogout }) => {
  const elementsRef = useRef([]);
  const carouselBeneficiosRef = React.createRef();
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    carouselProgramasItems: [],
    isVideoVisible: false,
    videoUrl: "https://player.vimeo.com/video/891950744",
    guiaInicio: {
      nombre: "",
      correo: ""
    }
  });

  useEffect(() => {
    setState({
      carouselProgramasItems: getCarouselProgramasItems(),
    });

    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  // const enviarGuiaForm = useCallback((event) => {
  //   event.preventDefault();
  // }, []);

  return (
    <>
      <ScrollToAnchor />
      <SEO
        title="Página principal"
        description="Descripción de la página principal."
      />
      <LazyLoadComponent>
        <VideoPlayer
          url={state.videoUrl}
          visible={state.isVideoVisible}
          onHide={() => setState({ isVideoVisible: false })}
          propsVideo={{
            controls: true,
            controlsList: "nodownload"
          }}
          propsContainer={{
            style: {
              height: "auto"
            }
          }}
        />
      </LazyLoadComponent>

      <HeaderNav />
      <div className={`w-full justify-between items-center animate__animated animate__fadeIn`} style={{ height: "calc(1101 / 1920 * 100%)", transition: "all 300ms linear" }}>
        <Carousel
          value={state.carouselProgramasItems}
          numVisible={1}
          numScroll={1}
          className="custom-carousel"
          circular
          showNavigators={false}
          autoplayInterval={0}
          itemTemplate={CarouselProgramasTemplate}
        />
      </div>

      {/* <LazyLoadComponent>
        <a href="https://jimefitmom.vip/manuales-para-un-embarazo-y-posparto-saludable/" rel="noreferrer noopener" target="_blank" className='flex items-center mt-2 mb-20 sm:mb-6 lg:mb-10 xl:mb-14'>
          <Image src="/assets/images/banners/banners-home.png" alt="manual banner" className='flex w-full' imageClassName='w-full' />
        </a>
      </LazyLoadComponent> */}

      <LazyLoadComponent>
        <div
          className="-mt-[50px] bg-white bg-center bg-cover bg-no-repeat w-full"
          style={{
            backgroundImage: `url("${require("../assets/images/bg_degraded/1.png")}")`
          }}
        >
          <div className="xl:container mx-auto px-6 pt-4">
            <div className='grid md:grid-cols-2 gap-14 mt-14 mb-16 items-center justify-center'>
              <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInLeft"} className='animate__animated'>
                <ReactPlayer
                  className="!h-full !w-full cover-video"
                  url={require("../assets/videos/home-app.mp4")}
                  playing={true}
                  loop={true}
                  controls={false}
                  muted={true}
                  playsinline={true}
                />
              </div>
              <LazyLoadComponent>
                <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInRight"} className='animate__animated mt-2 text-center md:text-start'>
                  <h1 className='text-xl md:text-3xl lg:text-4xl text-DeepKoamaru mt-7 lg:pr-16'>
                    Entrena donde sea con la <span className='font-golos-bold text-Crayola'>app de ejercicios</span> más completa para <span className='font-golos-bold text-Crayola'>embarazo</span> y <span className='font-golos-bold text-Crayola'>posparto</span>
                  </h1>
                  <div className='mt-10 flex flex-wrap gap-2 items-center justify-center md:justify-start'>
                    <Link to="https://apps.apple.com/mx/app/jimefitmom-pre-y-posnatal/id1615435709?l=en" target={"_blank"}>
                      <LazyLoadImage
                        alt={"App store Download"}
                        className='object-cover h-[60px] max-w-[160px]'
                        height={60}
                        src={require('../assets/images/Appstore.png')}
                        width={160}
                      />
                    </Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.jimena.jimefitmom&hl=en_US&gl=US" target={"_blank"}>
                      <LazyLoadImage
                        alt={"Play store Download"}
                        className='object-cover h-[60px] max-w-[160px]'
                        height={60}
                        src={require('../assets/images/Googleplay.png')}
                        width={160}
                      />
                    </Link>
                  </div>
                </div>
              </LazyLoadComponent>
            </div>
          </div>
        </div>
        <div className="xl:container mx-auto px-6 pb-4 bg-white">
          <div id="programas" className='mt-24 mb-20'>
            <h1
              ref={(el) => elementsRef.current.push(el)}
              id="programas-de-entrenamiento"
              className='font-bebas text-3xl lg:text-4xl text-DeepKoamaru text-center mb-0 animate__animated'
              data-animation={"animate__fadeInDown"}
            >
              CONOCE MIS PROGRAMAS
            </h1>
            <p className='font-golos-medium text-DeepKoamaru text-md lg:text-lg text-center mb-0'>Programas de ejercicio para Embarazo, Posparto y Avanzado</p>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-0 justify-center mt-6'>
              <div>
                <div className='w-full flex justify-center items-center mx-auto max-h-[477px]'>
                  <LazyLoadImage
                    alt={"Fitmom embarazo"}
                    height={480}
                    src={require('../assets/images/home/programa1-img.jpg')}
                    width={365}
                    style={{ maxWidth: "90%" }}
                  />
                </div>
                <div className='hidden md:flex relative timeline-right justify-center items-center mt-6'>
                  <Image
                    src={require('../assets/images/icon-bullet.png')}
                    alt="programa Embarazo"
                    className='flex items-center mx-auto'
                    imageStyle={{ maxWidth: 14, zIndex: 1 }}
                    height={14}
                    width={14}
                  />
                </div>
                <div className='mt-6 flex flex-col items-center justify-center px-4'>
                  <h2 className='mb-2 font-golos-semibold text-Crayola text-xl text-center'>Fitmom Embarazo</h2>
                  <p className='text-DarkJungleGreen text-sm text-center md:min-h-[3.7rem]'>Programa de ejercicios para mujeres en cualquier trimestre de embarazo y mujeres planeando un embarazo.</p>
                  <ButtonPrimary text="Ver detalles" classNameContainer="mt-6 md:mt-8" onClick={() => navigate("/programas/fitmom-embarazo")} />
                </div>
              </div>
              <div>
                <div className='w-full flex justify-center items-center mx-auto max-h-[477px]'>
                  <LazyLoadImage
                    alt={"Fitmom posparto"}
                    height={480}
                    src={require('../assets/images/home/programa2-img.jpg')}
                    width={365}
                    style={{ maxWidth: "90%" }}
                  />
                </div>
                <div className='hidden md:flex w-full items-center mt-6'>
                  <div style={{ height: 1, width: "100%", borderStyle: "solid", borderBottomWidth: 1, borderColor: "#342566" }} />
                  <Image src={require('../assets/images/icon-bullet.png')} alt="programa Posparto" className='mx-auto' imageStyle={{ maxWidth: 14, zIndex: 1 }} height={14} width={14} />
                  <div style={{ height: 1, width: "100%", borderStyle: "solid", borderBottomWidth: 1, borderColor: "#342566" }} />
                </div>
                <div className='mt-6 flex flex-col items-center justify-center px-4'>
                  <h2 className='mb-2 font-golos-semibold text-Crayola text-xl text-center'>Fitmom Posparto</h2>
                  <p className='text-DarkJungleGreen text-sm text-center min-h-[3rem]'>Programa de ejercicios para la recuperación después del parto o la cesárea y ejercicios para corregir diástasis, incontinencia, vientre abultado (mommy pooch), dolor de espalda o dolor pélvico.</p>
                  <ButtonPrimary text="Ver detalles" styleContainer={{ marginTop: "2em" }} onClick={() => navigate("/programas/fitmom-posparto")} />
                </div>
              </div>
              <div>
                <div className='w-full flex justify-center items-center mx-auto max-h-[477px]'>
                  <LazyLoadImage
                    alt={"Fitmom strength"}
                    height={480}
                    src={require('../assets/images/home/programa3-img.jpg')}
                    width={365}
                    style={{ maxWidth: "90%" }}
                  />
                </div>
                <div className='hidden md:flex relative timeline-left justify-center items-center mt-6'>
                  <Image src={require('../assets/images/icon-bullet.png')} alt="programa Strength" className='flex items-center mx-auto' imageStyle={{ maxWidth: 14, zIndex: 1 }} height={14} width={14} />
                </div>
                <div className='mt-6 flex flex-col items-center justify-center px-4'>
                  <h2 className='mb-2 font-golos-semibold text-Crayola text-xl text-center'>Fitmom Strength</h2>
                  <p className='text-DarkJungleGreen text-sm text-center min-h-[3rem]'>Programa de ejercicios avanzados para mujeres que han completado al menos 6 meses del programa posparto y desean aumentar la fuerza y resistencia.</p>
                  <ButtonPrimary text="Ver detalles" styleContainer={{ marginTop: "2em" }} onClick={() => navigate("/programas/fitmom-strength")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div className='py-10 bg-Magnolia'>
          <div className="xl:container mx-auto px-6">
            <div className='flex flex-wrap gap-10 items-center justify-center'>
              <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInLeft"} className='animate__animated relative flex items-center justify-center'>
                <div className='flex justify-center items-center mx-auto'>
                  <LazyLoadImage
                    alt={"Video de entrena"}
                    className='mx-auto max-w-[240px] sm:max-w-[320px] lg:max-w-[460px]'
                    height={373}
                    src={require('../assets/images/home/img-video2.png')}
                    style={{ borderRadius: 25, width: "90%" }}
                    width={414}
                  />
                </div>
                <div className='absolute c-pointer active:scale-90 transition ease-in-out' onClick={() => setState({ isVideoVisible: true })}>
                  <LazyLoadImage
                    alt={"Play icon"}
                    className='mx-auto'
                    height={108}
                    src={require('../assets/images/play-btn.png')}
                    style={{ width: "90%", maxWidth: 120 }}
                    width={108}
                  />
                </div>
              </div>
              <div className='flex-1 text-center md:text-left sm:min-w-[320px]' style={{ maxWidth: 390 }}>
                <LazyLoadImage
                  alt={"Logo icon"}
                  className='mx-auto md:mx-none'
                  height={30}
                  src={require('../assets/images/logo-video.png')}
                  style={{ width: "90%", maxWidth: 120 }}
                  width={120}
                />
                <h1 ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInRight"} className='animate__animated font-medium text-DeepKoamaru text-4xl mt-8'>Entrena desde cualquier país <span className='font-golos-bold'>y únete a mi comunidad.</span></h1>
                <p className='font-medium text-DeepKoamaru mt-8' style={{ fontSize: ".920em" }}>Únete a las miles de mamás que han experimentado los beneficios de mis programas de ejercicios durante el embarazo y el posparto.</p>
                {/* <div className='flex justify-center md:justify-start'>
                  <ButtonPrimary
                    text="Comienza tu prueba gratis"
                    withIcon
                    styleContainer={{ marginTop: "2em" }}
                    onClick={() => navigate("/programas/fitmom-embarazo")}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div className='bg-white'>
          <div className="xl:container mx-auto px-6 max-w-5xl">
            <div
              ref={(el) => elementsRef.current.push(el)}
              data-animation={"animate__fadeInDown"}
              className='animate__animated flex flex-row items-center gap-6 mt-10 mb-10'
            >
              <div className='flex-1'>
                <h1 className='font-golos-semibold text-DeepKoamaru text-left text-2xl'>
                  Estos son los beneficios que lograrás al entrenar conmigo
                </h1>
              </div>
              <BeneficiosButtonsCarousel carouselRef={carouselBeneficiosRef} />
            </div>
            <BeneficiosCarousel customRef={carouselBeneficiosRef} />
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div className='mt-20 pt-5 bg-Magnolia bg-cover bg-no-repeat' style={{ backgroundImage: `url("${require("../assets/images/bg_degraded/2.png")}")` }}>
          <div className="xl:container mx-auto px-10 mx-auto max-w-5xl">
            <div className='grid sm:grid-cols-[2fr_1.2fr] items-center justify-center'>
              <div
                ref={(el) => elementsRef.current.push(el)}
                data-animation={"animate__fadeInLeft"}
                className='animate__animated relative pt-12 pb-16 mx-auto max-w-[390px]'
              >
                <p className='text-xl sm:text-3xl font-medium text-DeepKoamaru'>
                  Con mi método <span className='font-golos-semibold'>jimefitmom</span> vivirás un <span className='font-golos-semibold'>embarazo y posparto</span> más saludables y llenos de energía,
                </p>
                <p className='mt-2 text-sm font-medium text-DeepKoamaru'>
                  con ejercicios seguros y respaldados por la ciencia adaptados a cada una de las etapas de la maternidad
                </p>
              </div>
              <div className='relative h-full w-full'>
                <div className='absolute left-0 h-full'>
                  <LazyLoadImage
                    alt={""}
                    className='w-full h-full object-contain'
                    imageClassName='mx-auto md:mx-none'
                    src={require('../assets/images/banner-img.png')}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <ContenidoClases />
      </LazyLoadComponent>

      <LazyLoadComponent>
        <EspecialistaCertificada />
      </LazyLoadComponent>

      <LazyLoadComponent>
        <RazonesEntrenarJimeFitMom />
      </LazyLoadComponent>

      <LazyLoadComponent>
        <TestimoniosClientas />
      </LazyLoadComponent>

      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </>
  );
};

export default Index;