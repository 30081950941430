import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import { ReactComponent as IconoBack } from "../../assets/icons/flechaback-icon.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import { useCustomState } from '../../hooks/useCustomState';
import Loading from '../../components/Loading';
import { ButtonCustom, ButtonPrimary } from '../../components/Button';
import { getCores } from '../../api/client/cores';
import { optionsToast } from '../../config/toast';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { obtenerDuracionFormateada } from '../../utils/parseDuration';

const Core = ({ isOnlyCategory }) => {
  const { id_category, id_subcategory } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    cores: [],
  });

  useEffect(() => {
    loadData(id_category, id_subcategory);

    // eslint-disable-next-line
  }, [id_category, id_subcategory]);

  const loadData = useCallback(async (id_category, id_subcategory) => {
    try {
      setState({ loading: true });
      const _cores = await getCores(null, {
        populate: ["categoria", "sub_categoria"],
        filters: {
          category: [id_category],
          subCategory: [id_subcategory],
        }
      });

      setState({
        cores: _cores?.data,
        loading: false,
      });
    } catch (error) {
      setState({ cores: [], loading: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const { getCategory, getSubCategory, formatDuration, titlePage, descriptionSubCategory } = useMemo(() => {
    const core = state?.cores?.[0];
    const _category = core?.categorias?.find(item => parseInt(item?.categoryID) === parseInt(id_category));
    const getCategory = _category?.Category?.name || "";
    const _subCategory = isOnlyCategory ? '' : core?.sub_categorias?.find(item => parseInt(item?.subCategoryID) === parseInt(id_subcategory));
    const getSubCategory = _subCategory?.SubCategory?.name || "";

    const formatDuration = (_core) => obtenerDuracionFormateada(_core?.durationVideo);

    const titlePage = `${getCategory}${getSubCategory ? ' / ' + getSubCategory : ''}`;
    const descriptionSubCategory = isOnlyCategory ? _category?.Category?.description : _subCategory?.SubCategory?.description;

    return { getCategory, getSubCategory, formatDuration, titlePage, descriptionSubCategory };
  }, [state?.cores, id_category, id_subcategory, isOnlyCategory]);

  return (
    <>
      <SEO
        title={titlePage || "Entrenamiento de Core"}
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />

      <div className='bg-Magnolia h-full pb-4'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            <div className='flex flex-col gap-4'>
              {(user?.subscription?.isActive !== true) ? (
                <div className='flex flex-col items-center justify-center gap-6'>
                  <div className='bg-white px-4 py-6 rounded-xl w-full'>
                    <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
                    <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                  {state.loading ? (
                    <Loading />
                  ) : (state.cores?.length === 0) ? (
                    <div className='flex flex-col gap-6 justify-center'>
                      <p className='font-golos-semibold text-red-600 text-center text-lg'>No se encontraron resultados.</p>
                      <ButtonCustom
                        text={"REGRESAR"}
                        withIconBack={true}
                        iconBack={{ fill: "#fff" }}
                        classNameContainer="flex justify-center"
                        classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                        onClick={() => navigate(isOnlyCategory ? `/mi-cuenta/cores` : `/mi-cuenta/cores/category/${id_category}`)}
                      />
                    </div>
                  ) : (
                    <div className='space-y-8'>
                      <div className='flex flex-col text-center md:text-left'>
                        <p className='font-golos-semibold text-Crayola text-2xl'>Entrenamiento de Core / {titlePage}</p>
                        <p>{descriptionSubCategory || (`Encuentra los core relacionados con el entrenamiento de ${getSubCategory || getCategory}.`)}</p>
                      </div>
                      <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-6'>
                        {state.cores?.length > 0 && state.cores?.map((item, index) => (
                          <div key={`core-key-${item?.id || index}`} className="flex flex-col space-y-2 overflow-hidden">
                            <div className='group relative rounded-xl overflow-hidden'>
                              <div className="absolute inset-0 top-0 left-0 bg-black bg-opacity-0 opacity-0 group-hover:bg-opacity-30 group-hover:opacity-100 rounded-2xl transition duration-300" />
                              <div
                                className="absolute inset-0 flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition duration-300"
                                onClick={() => item?.id && navigate(`clase-${item?.id}`)}
                              >
                                <div className="bg-black bg-opacity-50 p-2 rounded-full">
                                  <svg className="w-10 h-10 text-white" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M6 4l8 6-8 6V4z"></path>
                                  </svg>
                                </div>
                              </div>
                              <Image
                                src={item?.image}
                                imageClassName="w-full object-cover h-full rounded-xl"
                                className='flex bg-white border border-gray-100 min-h-[160px] h-[160px] rounded-xl'
                                onError={(event) => {
                                  event.target.onerror = null; // previene un bucle infinito
                                  event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                                }}
                              />
                              <div className='absolute bottom-0 right-0 pointer-events-none'>
                                <p className='mr-2 mb-2 bg-black/50 text-white text-muted px-2 py-1 rounded-md select-none'>
                                  {`${formatDuration(item)?.minutos || "00"}:${formatDuration(item)?.segundos || "00"}`}
                                </p>
                              </div>
                            </div>
                            <div className='c-pointer inline-flex w-full' onClick={() => item?.id && navigate(`clase-${item?.id}`)}>
                              <div className='flex flex-col sm:flex-row gap-2 justify-center items-center'>
                                <div className='flex-1 text-center sm:text-left'>
                                  <p className='ml-3 font-golos-medium text-Crayola text-lg'>{item?.title}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className='flex items-center justify-center pt-10 pb-6'>
                        <ButtonPrimary
                          text={
                            <div className='flex items-center'>
                              <span className="d-block mr-3">
                                <IconoBack fill={"#fff"} width={18} />
                              </span>
                              <p className='font-golos flex items-center gap-1'>
                                Volver
                              </p>
                            </div>
                          }
                          withIcon={false}
                          classNameContainer="border-DeepKoamaru"
                          classNameButton={"!bg-DeepKoamaru hover:!bg-[#1e114b] py-3 px-6"}
                          onClick={() => navigate(isOnlyCategory ? `/mi-cuenta/cores` : `/mi-cuenta/cores/category/${id_category}`)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default React.memo(Core);