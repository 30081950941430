import { memo } from "react";
import { getRazonesEntrenar } from "../../misc/getRazonesEntrenar";

const ItemCard = memo(({ text, icon: Icon }) => (
  <div className={"bg-[#f9e9f5] rounded-xl w-full max-w-[240px] py-5 px-4"}>
    <div className="flex flex-col items-center justify-start gap-4">
      <Icon height={52} width={52} />
      <p className="font-golos text-md text-DeepKoamaru text-center">
        {text}
      </p>
    </div>
  </div>
));

const RazonesEntrenarJimeFitMom = () => {
  const razonesEntrenar = getRazonesEntrenar();

  return (
    <div className="bg-white py-16 px-6 w-full">
      <h1 className='font-bebas text-center text-3xl md:text-4xl text-DeepKoamaru mb-6'>
        ¿POR QUÉ ENTRENAR CON JIMEFITMOM?
      </h1>
      <div className="flex flex-row flex-wrap justify-center items-stretch gap-4">
        {razonesEntrenar.map((clase) => (
          <ItemCard key={clase.text} {...clase} />
        ))}
      </div>
    </div>
  );
};

export default memo(RazonesEntrenarJimeFitMom);