export const colors = {
  transparent: 'transparent',
  white2: '#ffffff',
  gris: {
    EEEEEE: '#EEEEEE',
    F2F2F2: '#F2F2F2',
    F7F7F7: '#F7F7F7',
    '9A9A9A': '#9A9A9A',
  },
  AntiFlashWhite: '#f2f1fb',
  Orchid: "#F59DD7",
  Orchid2: "#e4b0db",
  OrchidHover: "#dd90c7",
  PinkLace: "#F5E1FC",
  LavenderBlush: "#FDF0FA",
  Magnolia: "#F3F1FC",
  Magnolia2: "#e2e1f5",
  MagnoliaHover: "#e4e1f2",
  Crayola: "#7e73d6",
  CrayolaHover: "#6d62c5",
  DeepKoamaru: "#342566",
  DarkJungleGreen: "#191e25",
  VividRed: "#f11212",

  text: '#101107',
}