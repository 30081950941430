import {
  memo,
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  Fragment
} from "react";
import { Image } from "primereact/image";
import Slider from "react-slick";
import { ReactComponent as IconReview } from "../../../assets/icons/icon-review.svg";
import {
  getCarouselTestimoniosEmbarazoItems,
  getCarouselTestimoniosPospartoItems,
  getCarouselTestimoniosStrengthItems
} from "../../../misc/getCarouselItems";

const BeneficiosTemplateItem = memo(({ item }) => (
  <div className="h-full bg-white rounded-2xl text-left flex flex-col gap-8 px-5 py-8">
    {((!item.description_text || item.description_text?.trim() === "") && item.description_img) ? (
      <Image
        src={item?.description_img}
        alt="Testimonio description"
        className="flex items-center justify-center select-none"
        imageClassName={"object-contain min-h-[208px] max-h-[320px]"}
        preview={true}
        height={208}
        width={305}
      />
    ) : (
      <>
        <IconReview height={43} width={43} />
        <p className="text-sm text-DarkJungleGreen mb-0 whitespace-break-spaces">
          {item.description_text}
        </p>
      </>
    )}
  </div>
));

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: false,
  speed: 500,
  adaptiveHeight: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  cssEase: "linear",
  /* Esto es solo para cuando usa slidesToShow: 3.2 */
  // className: "center",
  // centerMode: true,
  // centerPadding: "26px",
  /* Fin Center */
  responsive: [
    { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

const TestimoniosProgramasCarousel = forwardRef(({ customRef, ...props }, forwardedRef) => {
  const [data, setData] = useState([]);
  const sliderRef = useRef(null);

  const loadData = useCallback(async () => {
    try {
      let response = [];

      if (props?.program === "embarazo") {
        response = getCarouselTestimoniosEmbarazoItems();
      } else if (props?.program === "posparto") {
        response = getCarouselTestimoniosPospartoItems();
      } else if (props?.program === "strength") {
        response = getCarouselTestimoniosStrengthItems();
      }

      const filteredData = props?.filterEnabled
        ? response.filter(item => item.description_text?.trim())
        : response;

      setData(filteredData);
    } catch {
      setData([]);
    }
  }, [props?.filterEnabled, props?.program]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useImperativeHandle(forwardedRef, () => ({
    next: () => (props?.customRef || sliderRef).current?.slickNext(),
    previous: () => (props?.customRef || sliderRef).current?.slickPrev(),
  }), [props?.customRef]);

  return (
    <div className="slider-container slider-flex-height slick-gap">
      {data.length > 0 ? (
        <Slider ref={customRef ?? sliderRef} {...sliderSettings}>
          {data.map((item, index) => (
            <BeneficiosTemplateItem key={`item-key-${index}`} item={item} />
          ))}
        </Slider>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
});

export default memo(TestimoniosProgramasCarousel);