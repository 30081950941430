import {
  memo,
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  Fragment
} from "react";
import Slider from "react-slick";
import { Image } from "primereact/image";
import {
  getCarouselBeneficiosProgramaEmbarazo,
  getCarouselBeneficiosProgramaPosparto,
  getCarouselBeneficiosProgramaStrength
} from "../../../misc/getCarouselBeneficiosProgramas";

const BeneficiosTemplateItem = memo(({ item }) => (
  <div
    className="h-full bg-white flex flex-col items-center justify-start rounded-xl px-5 py-12"
  >
    <div className="flex flex-col items-center justify-center gap-6 mx-auto max-w-[70%]">
      <Image
        src={item.icon}
        alt="Icono"
        height={43}
        width={46}
        imageStyle={{ height: 43, width: 46 }}
      />
      <p className="text-sm text-DarkJungleGreen text-center mb-0 whitespace-break-spaces">
        {item.text}
      </p>
    </div>
  </div>
));

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: false,
  autoplay: false,
  speed: 500,
  adaptiveHeight: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  cssEase: "linear",
  /* Esto es solo para cuando usa slidesToShow: 3.2 */
  // className: "center",
  // centerMode: true,
  // centerPadding: "26px",
  /* Fin Center */
  responsive: [
    { breakpoint: 960, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

const BeneficiosProgramasCarousel = forwardRef(({ customRef, ...props }, forwardedRef) => {
  const [data, setData] = useState([]);
  const sliderRef = useRef(null);

  const loadData = useCallback(async () => {
    try {
      let response = [];

      if (props?.program === "embarazo") {
        response = getCarouselBeneficiosProgramaEmbarazo();
      } else if (props?.program === "posparto") {
        response = getCarouselBeneficiosProgramaPosparto();
      } else if (props?.program === "strength") {
        response = getCarouselBeneficiosProgramaStrength();
      }

      setData(response);
    } catch {
      setData([]);
    }
  }, [props?.program]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useImperativeHandle(forwardedRef, () => ({
    next: () => (props?.customRef || sliderRef).current?.slickNext(),
    previous: () => (props?.customRef || sliderRef).current?.slickPrev(),
  }), [props?.customRef]);

  return (
    <div className="slider-container slider-flex-height slick-gap">
      {data.length > 0 ? (
        <Slider ref={customRef ?? sliderRef} {...sliderSettings}>
          {data.map((item, index) => (
            <BeneficiosTemplateItem key={`item-key-${index}`} item={item} />
          ))}
        </Slider>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
});

export default memo(BeneficiosProgramasCarousel);