import Icon1 from "../assets/icons/home/icon01.svg";
import Icon2 from "../assets/icons/home/icon02.svg";
import Icon3 from "../assets/icons/home/icon03.svg";
import Icon4 from "../assets/icons/home/icon04.svg";

export const getBeneficios = () => {
  return [
    {
      icon: Icon1,
      text: "Diseñado con ejercicios específicos para prepararte para las necesidades de la mujer durante el embarazo, parto y posparto incluyendo ejercicios de suelo pélvico y core."
    },
    {
      icon: Icon2,
      text: "Metodología probada para prevenir y corregir diástasis, incontinencia, vientre abultado (mommy pooch) y dolores comunes del embarazo y posparto."
    },
    {
      icon: Icon3,
      text: "Guiados por una fitness coach pre y posnatal certificada que entiende los cambios y retos fisiológicos que pasa el cuerpo en las etapas de embarazo y posparto."
    },
    {
      icon: Icon4,
      text: "Ejercicios que te ayudarán a tener una recuperación más rápida y efectiva después del parto o de la cesárea trabajando desde la base con ejercicios para recuperar la fuerza de tu suelo pélvico y de tu pared abdominal."
    }
  ];
};