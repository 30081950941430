import React, {
  useCallback,
  // useContext,
  useEffect,
  useRef
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as RegaloIcon } from '../../assets/icons/programas/embarazo/icon-bono01.svg';
import bonoExtra1 from '../../assets/icons/programas/embarazo/icon-bono02.svg';
import bonoExtra2 from '../../assets/icons/programas/embarazo/icon-bono03.svg';
import bonoExtra3 from '../../assets/icons/programas/embarazo/icon-bono04.svg';
import bonoExtra4 from '../../assets/icons/programas/embarazo/icon-bono05.svg';
import bonoExtra5 from '../../assets/icons/programas/embarazo/icon-bono06.svg';
import HeaderNav from '../../components/Header';
import Footer from '../../components/Footer';
import SEO from '../../components/SEO';
import { usePromocion } from '../../components/PromocionProvider';
import VideoPreviewPlayer from '../../components/VideoPreviewPlayer';
import { getBannerPrograma, getProgramas } from '../../api/client/programas';
import { useCustomState } from '../../hooks/useCustomState';
import intersectionObserver from '../../utils/intersectionObserver';
import PreguntasFrecuentes from '../components/PreguntasFrecuentes';
import EntrenaApp from './components/EntrenaApp';
import Membresias from './components/Membresias';
import BenefitsGrid from './components/BenefitsGrid';
import BeneficiosProgramasCarousel from './components/BeneficiosProgramasCarousel';
import BeneficiosButtonsCarousel from '../components/BeneficiosButtonsCarousel';
import TestimoniosProgramasCarousel from './components/TestimoniosProgramasCarousel';
import DisfrutaEnDispositivos from './components/DisfrutaEnDispositivos';
// import GlobalContext from '../../contexts/GlobalContext';
import { benefitsEmbarazoData } from '../../misc/getBenefitsProgramData';
import { getPreguntasFrecuentesEmbarazo } from '../../misc/getPreguntasFrecuentes';
import { setSubscription } from '../../redux/reducers/subscriptionSlice';

const ProgramaEmbarazo = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carouselBeneficiosRef = React.createRef();
  const carouselTestimoniosRef = React.createRef();
  const elementsRef = useRef([]);

  // const { setModal } = useContext(GlobalContext);
  const { state: statePromocion } = usePromocion();
  const [state, setState] = useCustomState({
    preguntasFrecuentes: [],
    isVideoVisible: false,
    portada_video_introduccion: null,
    video_introduccion: null,
    programaDetalles: null,
    bannerDetalles: null,
  });

  useEffect(() => {
    searchSuscripcionData();
    loadProgram();

    (async () => {
      setState({
        preguntasFrecuentes: await getPreguntasFrecuentesEmbarazo(),
      });
    })();

    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  const searchSuscripcionData = useCallback(async () => {
    // const _getVimeoAPI = async (id) => (await getDataVimeoApi(id))?.data;

    setState({
      portada_video_introduccion: "/assets/images/embarazo_fitmom.jpg", // (await _getVimeoAPI(821854458))?.video?.thumbs?.base,
      video_introduccion: {
        url: "/assets/videos/embarazo_fitmom.mp4",
      },
    });
  }, [setState]);

  const loadProgram = useCallback(async () => {
    try {
      const programasResponse = await getProgramas({ code: name });
      const programDetails = programasResponse?.data?.[0];

      let bannerResponse = null;
      if (programDetails?.id) {
        try {
          bannerResponse = await getBannerPrograma({ programID: programDetails.id });
        } catch (bannerError) { }
      }

      setState({
        programaDetalles: programDetails,
        bannerDetalles: bannerResponse?.data || { image: require('../../assets/images/programas/banners/banner-embarazo.jpg') },
      });
    } catch (error) {
      setState({
        bannerDetalles: { image: require('../../assets/images/programas/banners/banner-embarazo.jpg') },
      });
    }
  }, [name, setState]);

  const seleccionarSuscripcion = useCallback((plan, extraParams = {}) => {
    dispatch(setSubscription({
      data: {
        id: state.programaDetalles?.id,
        tipo_programa: "Programa de entrenamiento",
        code: state.programaDetalles?.code,
        nombre: state.programaDetalles?.name,
        plan,
        precio: plan === "mensual" ? state.programaDetalles?.cost_month : state.programaDetalles?.cost_biannual,
      }
    }));

    const params = new URLSearchParams(extraParams).toString();
    const url = params ? `/carrito/embarazo-${plan}?${params}` : `/carrito/embarazo-${plan}`;

    navigate(url);
  }, [state.programaDetalles, dispatch, navigate]);

  return (
    <>
      <SEO
        title="Programa de entrenamiento"
        description="Para mujeres en cualquier trimestre de embarazo o en planeación."
      />
      {/* Formato de imagenes */}
      {/* <div
        className={`bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center transition-all ${statePromocion.isActive ? "mt-[3.1rem]" : ""}`}
        style={{
          ...(state.bannerDetalles?.image ? { backgroundImage: `url(${state.bannerDetalles.image})` } : {}),
          paddingTop: "calc(720 / 1920 * 100%)"
        }}
      >
        <HeaderNav />
      </div> */}
      {/* Formato de videos */}
      <div
        className={`relative overflow-hidden bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center transition-all ${statePromocion.isActive ? "mt-[3.1rem]" : ""}`}
        style={{
          paddingTop: "calc(1080 / 1920 * 100%)"
        }}
      >
        <VideoPreviewPlayer
          playerContainerClassName="absolute top-0 left-0 w-full h-full pointer-events-none z-1"
          hasOverlay={false}
          autoplay={true}
          loop={true}
          controls={false}
          altText='Video embarazo'
          videoSrc={require("../../assets/videos/programas/VIDEO-Embarazo.mp4")}
        />
        <HeaderNav />
      </div>

      <div
        className='bg-LavenderBlush bg-left lg:bg-center bg-cover bg-no-repeat w-full'
        style={{
          backgroundImage: `url("${require("../../assets/images/bg_degraded/4.jpg")}")`
        }}
      >
        <div className="xl:container mx-auto px-6 max-w-6xl">
          <div className='grid sm:grid-cols-2 gap-4'>
            <div className='order-2 sm:order-1 max-w-[520px] mx-auto pt-[2rem] sm:pt-[4.5rem] sm:pb-[4.5rem] pb-[2rem] text-center sm:text-left'>
              <h1
                ref={(el) => elementsRef.current.push(el)}
                className='animate__animated font-golos-semibold text-xl lg:text-2xl text-Crayola'
                data-animation={"animate__fadeInDown"}
              >
                Programa de entrenamiento
              </h1>
              <h1
                ref={(el) => elementsRef.current.push(el)}
                className='animate__animated font-bebas text-4xl md:text-5xl lg:text-7xl text-DeepKoamaru'
                data-animation={"animate__fadeInUp"}
              >
                FITMOM EMBARAZO
              </h1>
              <p
                className='font-golos-medium text-md lg:text-xl text-DarkJungleGreen mt-2'
              >
                Programa de ejercicios para mujeres en cualquier trimestre de embarazo o planeando un embarazo.
              </p>
            </div>
            <div className='order-1 sm:order-2 relative'>
              <div className='-my-[2rem] sm:my-0 sm:absolute sm:top-0 sm:translate-y-[-45%]'>
                <LazyLoadImage
                  alt="App image"
                  src={require("../../assets/images/programas/img-app-embarazo.png")}
                  className='mx-auto max-w-[220px] sm:mx-none xs:max-w-[300px] sm:max-w-[420px] w-full'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white pt-6 md:pt-16 pb-16 w-full'>
        <div className='xl:container mx-auto px-6 max-w-6xl'>
          <h1
            ref={(el) => elementsRef.current.push(el)}
            className='animate__animated font-bebas text-3xl md:text-4xl lg:text-5xl text-center text-DeepKoamaru mb-8'
            data-animation={"animate__fadeInDown"}
          >
            CON ESTE PROGRAMA PODRÁS:
          </h1>
          <BenefitsGrid
            benefits={benefitsEmbarazoData}
            program="embarazo"
          />
          <div className='mt-8 xs:px-6 sm:px-12'>
            <VideoPreviewPlayer
              hasOverlay={false}
              playerContainerClassName='rounded-2xl'
              altText='Thumbnail video embarazo'
              thumbnail={require("../../assets/images/programas/video-embarazo.jpg")}
              videoSrc={require("../../assets/videos/video-embarazo.mp4")}
            />
          </div>
        </div>
      </div>

      <div
        className='bg-LavenderBlush w-full overflow-x-hidden'
      >
        <div className="xl:container mx-auto px-6">
          <div className='grid sm:grid-cols-2 gap-10 max-w-6xl mx-auto'>
            <div ref={(el) => elementsRef.current.push(el)} className='animate__animated py-16' data-animation={"animate__fadeInLeft"}>
              <h1 className='leading-7 font-golos-bold text-DeepKoamaru text-center sm:text-left text-2xl mb-4'>
                ¿Te sientes insegura sobre cómo entrenar durante tu embarazo y temes que pueda poner en riesgo tu salud o la de tu bebé?
              </h1>
              <div className='flex flex-col gap-6 mb-12'>
                <p className='font-golos-medium leading-5 text-text text-center sm:text-left'>
                  No se trata solamente de hacer ejercicio, sino de hacer los ejercicios que tu cuerpo necesita en esta etapa. La falta de actividad o el ejercicio inapropiado pueden lesionarte o provocarte disfunciones como la diástasis, la incontinencia, dolores de espalda y pélvicos. Créeme que yo ya lo viví y sufrí muchas de estos problemas por no tener la guía adecuada en su momento.
                </p>
                <p className='font-golos-medium leading-5 text-DarkJungleGreen text-center sm:text-left'>
                  Por eso, como Fitness Coach Certificada Pre y Posnatal que conoce y entiende los cambios por los que atraviesa tu cuerpo en esta etapa, te guiaré con ejercicios seguros y respaldados por la ciencia para ayudarte a vivir un embarazo saludable y sin dolor.
                </p>
              </div>
              <div className='flex justify-center sm:justify-start'>
                <a href="#programas" className='inline-flex font-golos-semibold bg-Crayola text-white rounded-full py-4 px-5 no-underline text-sm text-center'>
                  PRUEBA AHORA MIS 7 DÍAS GRATIS
                </a>
              </div>
            </div>
            <div ref={(el) => elementsRef.current.push(el)} className='animate__animated relative hidden sm:flex' data-animation={"animate__fadeInRight"}>
              <LazyLoadImage
                alt="cover image"
                src={require("../../assets/images/programas/img-jimefit.png")}
                className='object-contain absolute bottom-0 pt-8 w-full h-full'
              />
            </div>
          </div>
        </div>
      </div>

      <Membresias
        typeProgram={state.programaDetalles?.code}
        onSubscribe={seleccionarSuscripcion}
        options={{
          price_month: state.programaDetalles?.cost_month,
          price_biannual: state.programaDetalles?.cost_biannual,
        }}
      />

      <EntrenaApp program="embarazo" />

      <div className='pt-10 pb-16 bg-[#eae9f7] overflow-hidden'>
        <div className="xl:container mx-auto px-10">
          <div
            ref={(el) => elementsRef.current.push(el)}
            data-animation={"animate__fadeInDown"}
            className='animate__animated flex flex-col sm:flex-row items-center gap-6 mt-10 mb-10'
          >
            <div className='flex-1'>
              <h1 className='max-w-[520px] font-golos-semibold text-DeepKoamaru text-center sm:text-left text-2xl whitespace-break-spaces'>
                {`Esto es lo que incluye tu suscripción \nal programa de ejercicio Fitmom Embarazo.`}
              </h1>
            </div>
            <BeneficiosButtonsCarousel carouselRef={carouselBeneficiosRef} />
          </div>
          <BeneficiosProgramasCarousel
            customRef={carouselBeneficiosRef}
            program="embarazo"
          />
          <div className='sm:mb-8 mt-14 sm:mt-20'>
            <div className='flex flex-col md:flex-row items-center justify-center gap-4'>
              <RegaloIcon height={48} width={48} />
              <h1 className='font-bebas text-DeepKoamaru text-center text-4xl md:text-5xl lg:text-6xl'>
                ADEMÁS LLÉVATE ESTOS BONOS EXTRA:
              </h1>
            </div>
            <div className='mt-8 sm:mt-16 flex flex-wrap items-center justify-center gap-10 sm:gap-16 w-full mx-auto'>
              <div className='flex items-center justify-center gap-5 md:gap-3 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra1}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Guía nutricional para \nreducir inflamación.`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-3 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra2}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Manual de ejercicio \nseguro en el embarazo.`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-3 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra3}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Guía para \nconstruir hábitos.`}
                </p>
              </div>

              <div className='flex items-center justify-center gap-5 md:gap-3 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra4}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Tarjetas de afirmaciones \npara tu embarazo y parto.`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-3 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra5}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Descuentos \ncon partners.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-16 bg-white'>
        <div className="xl:container mx-auto px-10">
          <div className='grid grid-cols-1 sm:grid-cols-[1.3fr_2fr] items-center gap-10'>
            <div className='max-w-[460px] mx-auto'>
              <p className='font-golos-semibold leading-7 text-DeepKoamaru text-2xl xs:text-3xl md:text-4xl text-center sm:text-left'>
                +15,000 mamás
              </p>
              <p className='font-golos leading-7 text-DeepKoamaru text-2xl xs:text-3xl md:text-4xl text-center sm:text-left'>
                ya han tomado mis programas y experimentado los beneficios.
              </p>
            </div>
            <div>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/img-programas.png")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#fbf5fa]'>
        <div className="xl:container mx-auto px-6 max-w-6xl">
          <div className='grid grid-cols-1 sm:grid-cols-[1.1fr_1.6fr] items-center gap-10'>
            <div className='mx-auto max-w-[260px] sm:max-w-[360px]'>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/img-jimecert.png")}
              />
            </div>
            <div className='sm:py-8 mb-10 sm:mb-0 space-y-7 text-center sm:text-left'>
              <h3 className='font-bebas text-3xl sm:text-5xl text-DeepKoamaru'>JIMENA CANTÚ</h3>
              <p className='font-golos-bold text-md'>
                Soy mamá de 3 niñas, Fitness Coach Certificada en Ejercicio Pre y Posnatal, Especialista en Ejercicios Correctivos, Suelo Pélvico y Core por Fit For Birth y AFPA.
              </p>
              <p className='font-golos text-md'>
                Con mis programas de entrenamiento he podido ayudar a más de 15,000 mamás a tener embarazos y pospartos más saludables, mejorando su experiencia de parto y recuperación. Mi misión es educar y empoderar a las mamás, proporcionándoles herramientas para que se sientan seguras y confiadas con su cuerpo y su maternidad.
              </p>
              <div className='mx-auto sm:mx-none max-w-[420px] pt-3'>
                <p className='font-golos-bold text-md text-Crayola'>
                  Recomendada por ginecólogos, doulas y fisioterapeutas en Estados Unidos, México y Latinoamérica.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='bg-LavenderBlush bg-center bg-[size:100%_100%] bg-no-repeat w-full'
        style={{
          backgroundImage: `url("${require("../../assets/images/bg_degraded/4.jpg")}")`
        }}
      >
        <div className="xl:container mx-auto sm:mr-none xl:mr-auto xl:pl-16">
          <div className='grid grid-cols-1 sm:grid-cols-[1fr_2fr] items-center gap-3'>
            <div className='mx-auto lg:ml-auto 2xl:mx-auto max-w-[420px] sm:max-w-[340px] px-10'>
              <h4 className='font-golos text-4xl md:text-[2.6rem] text-DeepKoamaru mt-10 text-center sm:text-left'>
                <span className='font-golos-semibold'>Comienza ahora</span> a vivir un embarazo saludable
              </h4>
            </div>
            <div className='mx-auto'>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/banner2-img.png")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white w-full'>
        <div className="xl:container mx-auto px-8 lg:px-16">
          <div className='grid grid-cols-1 lg:grid-cols-[1.5fr_1.3fr] items-center gap-10'>
            <div className='lg:pl-16 py-16 lg:py-0 space-y-6 text-center lg:text-left'>
              <h4 className='font-bebas text-[2.5rem] leading-[2rem] lg:text-[2.8rem] lg:leading-[2rem] text-DeepKoamaru'>
                ¿ESTE PROGRAMA ES PARA MI?
              </h4>
              <p className='font-golos-semibold leading-6 text-xl lg:leading-7 lg:text-2xl text-Crayola'>
                Este programa está diseñado para adaptarse a cualquier trimestre de embarazo y para cualquier nivel de fitness.
              </p>
              <p className='lg:pr-8 text-md'>
                Dentro del programa encontrarás diferentes tipos de ejercicios incluyendo ejercicios de fuerza, movilidad y relajación para que puedas adaptar conforme lo necesites. También podrás encontrar rutinas de preparación para el parto y rutinas para embarazo en reposo. <span className='font-golos-bold'>¡Todas tus necesidades cubiertas en un mismo lugar!</span>
              </p>
              <div>
                <a href="#programas" className='mt-1 inline-flex bg-Crayola font-golos-semibold text-white py-3.5 px-10 rounded-full'>
                  UNIRME A LOS 7 DÍAS GRATIS
                </a>
              </div>
            </div>
            <div className='hidden lg:block max-w-[420px] mr-auto'>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/img-esparami.png")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='py-12 bg-[#eae9f7]'>
        <div className="xl:container mx-auto px-6 mb-12 overflow-x-hidden">
          <div
            ref={(el) => elementsRef.current.push(el)}
            data-animation={"animate__fadeInDown"}
            className='animate__animated flex flex-row items-center gap-6 mt-10 mb-14 md:mr-16'
          >
            <div className='flex-1'>
              <h1 className='max-w-[520px] font-golos-bold text-DeepKoamaru text-left text-2xl'>
                Esto es lo que dicen mis alumnas
              </h1>
            </div>
            <BeneficiosButtonsCarousel carouselRef={carouselTestimoniosRef} />
          </div>
          <TestimoniosProgramasCarousel
            customRef={carouselTestimoniosRef}
            program="embarazo"
          />
        </div>
      </div>

      <DisfrutaEnDispositivos program="embarazo" />

      <div className='py-16 bg-[#fbf5fa]'>
        <div className="xl:container mx-auto px-6">
          <div className='mt-4 mb-10'>
            <h1
              ref={(el) => elementsRef.current.push(el)}
              className='font-bebas text-DeepKoamaru text-center text-[2.4rem] animate__animated'
              data-animation={"animate__slideInDown"}
            >
              ¿AÚN TE QUEDAN DUDAS?
            </h1>
            <p className='font-golos-medium text-Crayola text-lg text-center'>
              No te preocupes, nosotras te respondemos
            </p>
          </div>
          <div>
            <PreguntasFrecuentes
              data={state.preguntasFrecuentes}
            />
          </div>
          <div className='flex items-center justify-center mt-12 mb-5'>
            <div className='bg-Crayola py-3.5 px-12 rounded-full inline-flex'>
              <p className='font-golos-semibold leading-7 text-AntiFlashWhite text-center'>
                ¿TE QUEDARON DUDAS?, ESCRIBE A <a href='mailto:hola@jimefitmom.com' className='font-golos-semibold text-none'>HOLA@JIMEFITMOM.COM</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProgramaEmbarazo;