import IconEmba01 from "../assets/icons/programas/embarazo/icon-progr01.svg";
import IconEmba02 from "../assets/icons/programas/embarazo/icon-progr02.svg";
import IconEmba03 from "../assets/icons/programas/embarazo/icon-progr03.svg";
import IconEmba04 from "../assets/icons/programas/embarazo/icon-progr04.svg";
import IconEmba05 from "../assets/icons/programas/embarazo/icon-progr05.svg";
import IconEmba06 from "../assets/icons/programas/embarazo/icon-progr06.svg";

import IconPosp01 from "../assets/icons/programas/posparto/icon-progr01.svg";
import IconPosp02 from "../assets/icons/programas/posparto/icon-progr02.svg";
import IconPosp03 from "../assets/icons/programas/posparto/icon-progr03.svg";
import IconPosp04 from "../assets/icons/programas/posparto/icon-progr04.svg";

import IconStre01 from "../assets/icons/programas/strength/icon-progr01.svg";
import IconStre02 from "../assets/icons/programas/strength/icon-progr02.svg";
import IconStre03 from "../assets/icons/programas/strength/icon-progr03.svg";
import IconStre04 from "../assets/icons/programas/strength/icon-progr04.svg";
import IconStre05 from "../assets/icons/programas/strength/icon-progr05.svg";

export const benefitsEmbarazoData = [
  { icon: IconEmba01, text: 'Preparar tu cuerpo para los cambios físicos del embarazo y las demandas del parto y de la maternidad.', imageClass: 'w-11 h-11', wrapperClass: 'w-[36px]' },
  { icon: IconEmba02, text: 'Prevenir problemas comunes como diástasis, incontinencia y dolor de espalda.', imageClass: 'w-11 h-11', wrapperClass: 'w-[36px]' },
  { icon: IconEmba03, text: 'Aumentar tu energía y disminuir dolores y molestias del embarazo.', imageClass: 'w-full h-full', wrapperClass: 'h-[36px] w-[36px]' },
  { icon: IconEmba04, text: 'Mejorar tu desempeño en el parto.', imageClass: 'w-full h-full', wrapperClass: 'h-[36px] w-[36px]' },
  { icon: IconEmba05, text: 'Prevenir depresión posparto.', imageClass: 'mx-auto w-7 h-7', wrapperClass: 'w-[36px]' },
  { icon: IconEmba06, text: 'Facilitar tu recuperación en el posparto.', imageClass: 'w-12 h-12', wrapperClass: 'w-[36px]', paddingY: 'py-6' }
];

export const benefitsPospartoData = [
  { icon: IconPosp01, text: 'Rehabilitar y recuperar el cuerpo después del parto o la cesárea y regresar al ejercicio de forma segura.', imageClass: 'w-11', wrapperClass: 'w-[36px]' },
  { icon: IconPosp02, text: 'Aplanar el abdomen, reducir cintura y definir abdominales.', imageClass: 'w-8', wrapperClass: 'w-[36px]' },
  { icon: IconPosp03, text: 'Corregir diástasis, incontinencia, dolor de espalda baja, dolor pélvico o púbico.', imageClass: 'w-11 h-11', wrapperClass: 'h-[36px] w-[36px]' },
  { icon: IconPosp04, text: 'Construir fuerza y masa muscular después del parto o la cesárea.', imageClass: 'w-11', wrapperClass: 'h-[36px] w-[36px]' },
];

export const benefitsStrengthData = [
  { icon: IconStre01, text: 'Progresar en intensidad y dificultad \nen la coordinación y fuerza del core.', imageClass: 'w-8 h-8 mx-auto', wrapperClass: 'w-[36px]' },
  { icon: IconStre02, text: 'Seguir retando al cuerpo en fuerza \ny resistencia.', imageClass: 'w-7 h-7 mx-auto', wrapperClass: 'w-[36px]' },
  { icon: IconStre03, text: 'Aumentar masa muscular.', imageClass: 'w-8 h-8 mx-auto', wrapperClass: 'h-[36px] w-[36px]' },
  { icon: IconStre04, text: 'Acelerar quema \nde grasa.', imageClass: 'w-8 h-8 mx-auto', wrapperClass: 'h-[36px] w-[36px]' },
  { icon: IconStre05, text: 'Mejorar rendimiento.', imageClass: 'mx-auto w-6 h-6', wrapperClass: 'w-[36px]' },
];