import { ReactComponent as Icon1 } from "../assets/icons/razones_entrenar/icon-entreno01.svg";
import { ReactComponent as Icon2 } from "../assets/icons/razones_entrenar/icon-entreno02.svg";
import { ReactComponent as Icon3 } from "../assets/icons/razones_entrenar/icon-entreno03.svg";
import { ReactComponent as Icon4 } from "../assets/icons/razones_entrenar/icon-entreno04.svg";

export const getRazonesEntrenar = () => {
  return [
    {
      icon: Icon1,
      text: "Certificada por diversas instituciones líderes en Fitness Pre y Posnatal."
    },
    {
      icon: Icon2,
      text: "Metodología basada en evidencia científica."
    },
    {
      icon: Icon3,
      text: "Más de 15,000 mamás han experimentado los beneficios."
    },
    {
      icon: Icon4,
      text: "Recomendada por ginecólogos, doulas y fisioterapeutas en Estados Unidos, México y Latinoamérica."
    },
  ];
};