import { useState, useEffect, useCallback } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import IconPrev from "../../assets/icons/btn-back01.svg";
import IconPrevHover from "../../assets/icons/btn-back02.svg";
import IconNext from "../../assets/icons/btn-go01.svg";
import IconNextHover from "../../assets/icons/btn-go02.svg";

const BeneficiosButtonsCarousel = ({ carouselRef }) => {
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  const updateButtonsState = useCallback(() => {
    if (carouselRef?.current) {
      const slider = carouselRef.current?.innerSlider;
      if (!slider) return;

      const { slidesToScroll = 1, slidesToShow = 1, infinite } = slider.props;
      const { currentSlide = 0, slideCount = 0 } = slider.state;

      const lastSlideIndex = slideCount - slidesToShow;

      if (infinite) {
        setDisablePrev(false);
        setDisableNext(false);
      } else {
        setDisablePrev(currentSlide === 0);
        setDisableNext(currentSlide + slidesToScroll > lastSlideIndex);
      }
    }
  }, [carouselRef]);

  useEffect(() => {
    updateButtonsState();
  }, [updateButtonsState]);

  const handlePrev = () => {
    if (!disablePrev) {
      carouselRef.current?.slickPrev();
      setTimeout(updateButtonsState, 100);
    }
  };

  const handleNext = () => {
    if (!disableNext) {
      carouselRef.current?.slickNext();
      setTimeout(updateButtonsState, 100);
    }
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <button
        className="c-pointer appearance-none bg-transparent border-none outline-none focus:ring-0 p-0 m-0 hover:bg-transparent active:bg-transparent selection:bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"
        style={{ WebkitTapHighlightColor: "transparent" }}
        onClick={handlePrev}
        disabled={disablePrev}
      >
        <div className="group flex transition-all">
          <LazyLoadImage alt="icon prev" src={IconPrev} className="w-8 h-8 group-hover:hidden transition-all" />
          <LazyLoadImage alt="icon prev" src={IconPrevHover} className="w-8 h-8 hidden group-hover:flex transition-all" />
        </div>
      </button>
      <button
        className="appearance-none bg-transparent border-none outline-none focus:ring-0 p-0 m-0 hover:bg-transparent active:bg-transparent selection:bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"
        style={{ WebkitTapHighlightColor: "transparent" }}
        onClick={handleNext}
        disabled={disableNext}
      >
        <div className="group flex transition-all">
          <LazyLoadImage alt="icon next" src={IconNext} className="w-8 h-8 group-hover:hidden transition-all" />
          <LazyLoadImage alt="icon next" src={IconNextHover} className="w-8 h-8 hidden group-hover:flex transition-all" />
        </div>
      </button>
    </div>
  );
};

export default BeneficiosButtonsCarousel;