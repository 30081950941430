import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EspecialistaCertificada = () => (
  <div className="bg-[#fbf5fa] py-16">
    <div className='flex justify-center items-center'>
      <div className='inline-flex bg-white px-10 pb-7 rounded-2xl mx-6'>
        <div className='flex flex-col md:flex-row items-center gap-10 mt-10'>
          <div className='text-center md:text-left md:max-w-[220px]'>
            <h1 className='font-golos-semibold text-2xl text-DeepKoamaru mb-2'>Fitness Coach Certificada Pre y Posnatal</h1>
            <p className='text-sm text-DarkJungleGreen'>Especialista en Ejercicios Correctivos Pre y Postnatales y en Diástasis y Suelo Pélvico.</p>
          </div>
          <div className='py-4'>
            <LazyLoadImage
              alt={"Logo Certificados"}
              className='w-full mx-auto select-none pointer-events-none'
              height={114}
              src={require('../../assets/images/img-certif.png')}
              style={{ maxWidth: 540 }}
              width={540}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EspecialistaCertificada;