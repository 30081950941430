import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import { ReactComponent as IconoIr } from "../../assets/icons/flecha-icon.svg";
import { ReactComponent as IconoBack } from "../../assets/icons/flechaback-icon.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import { useCustomState } from '../../hooks/useCustomState';
import Loading from '../../components/Loading';
import { getCategoriesCores, getSubCategoriesCores } from '../../api/client/cores';
import { getSecciones } from '../../api/client/secciones';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { optionsToast } from '../../config/toast';
import { ButtonPrimary } from '../../components/Button';
import { isEmpty } from '../../utils';
import { getSubscription } from '../../api/client/mi_suscripcion';
import getDescriptionCore from '../../utils/getDescriptionCore';

const Cores = ({ isCategory }) => {
  const { id_category } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    cores: [],
    seccion: null,
    suscripcion_actual: null,
    nivel_2: null,
  });
  const titlePage = isCategory && (` / ${state?.cores?.[0]?.Category?.name || ""}`);
  const descriptionCategory = isCategory && state?.cores?.[0]?.Category?.description;

  useEffect(() => {
    loadDataNivel2();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData(isCategory, id_category);

    // eslint-disable-next-line
  }, [isCategory, id_category]);

  const { isEtapa1, isNivel1 } = useMemo(() => {
    const isEtapa1 = titlePage?.toLowerCase()?.includes("etapa 1");
    const isNivel1 = titlePage?.toLowerCase()?.includes("nivel 1");

    return { isEtapa1, isNivel1 };
  }, [titlePage]);

  const { goToNivel2 } = useMemo(() => {
    const goToNivel2 = isEmpty(state.nivel_2?.id) ? "" : `/mi-cuenta/cores/${state.nivel_2?.SubCategories?.length === 0 ? "only_category" : "category"}/${state.nivel_2?.id}`;

    return { goToNivel2 };
  }, [state.nivel_2]);

  const loadData = useCallback(async (isCategory, id_category) => {
    if (isEmpty(user?.programID)) return;

    try {
      setState({ loading: true });
      const _cores = isCategory === true ? await getSubCategoriesCores(id_category, { programID: user?.programID }) : await getCategoriesCores({ programID: user?.programID });
      const _secciones = await getSecciones({ code: "core" });
      let _suscripcion;

      try {
        _suscripcion = await getSubscription({ userID: user?.userID });
      } catch (error) { }

      setState({
        cores: _cores?.data,
        seccion: _secciones?.status === true && _secciones?.data?.[0],
        suscripcion_actual: _suscripcion?.data || null,
        loading: false,
      });
    } catch (error) {
      setState({ cores: [], loading: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, [user, setState]);

  const loadDataNivel2 = useCallback(async () => {
    if (isEmpty(user?.programID)) return;

    try {
      const _categories = await getCategoriesCores({ programID: user?.programID });
      const _find = _categories?.data?.find(item => item?.name?.toLowerCase()?.includes("nivel 2"));

      setState({ nivel_2: _find });
    } catch (error) { }
    // eslint-disable-next-line
  }, [user, setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  return (
    <>
      <SEO
        title={(titlePage && titlePage?.replace("/", "")) || "Entrenamiento de Core"}
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />

      <div className='bg-Magnolia h-full pb-4'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            {(user?.subscription?.isActive !== true) ? (
              <div className='flex flex-col items-center justify-center gap-6'>
                <div className='bg-white px-4 py-6 rounded-xl w-full'>
                  <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
                  <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
                </div>
              </div>
            ) : (
              <>
                {state.loading ? (
                  <Loading containerClassName="h-full" />
                ) : (
                  <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                      <div className='flex flex-col text-center md:text-left'>
                        <p className='font-golos-semibold text-Crayola text-2xl'>Entrenamiento de Core {titlePage || ""}</p>
                        <p>{descriptionCategory || (getDescriptionCore(state.suscripcion_actual?.Program?.code, state.seccion)) || "Explora y encuentra fácilmente todos los core del programa."}</p>
                      </div>
                      {state.loading ? (
                        <Loading />
                      ) : (state.cores?.length === 0) ? (
                        <p className='font-golos-semibold text-red-600 text-center text-lg'>No se encontraron resultados.</p>
                      ) : (
                        <>
                          {state.cores?.length > 0 ? (
                            <>
                              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
                                {state.cores?.map((item, index) => (
                                  <Link
                                    key={`cores-key-${item?.id || index}`}
                                    to={isCategory ? `subcategory/${item?.id}` : (item?.SubCategories?.length === 0 ? `only_category/${item?.id}` : `category/${item?.id}`)}
                                    className='c-pointer w-full'
                                  >
                                    <div className="flex flex-col w-full">
                                      <Image
                                        src={item?.image}
                                        imageClassName="w-full object-cover object-top h-full rounded-t-xl"
                                        className='bg-Magnolia min-h-[160px] h-[160px] rounded-t-xl'
                                        onError={(event) => {
                                          event.target.onerror = null; // previene un bucle infinito
                                          event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                                        }}
                                      />
                                      <div className='bg-Crayola pl-6 py-3 flex items-center space-x-2 -mt-[2px] rounded-b-xl'>
                                        <div className='flex-1'>
                                          <p
                                            className='font-golos-semibold text-white'
                                            style={{
                                              display: "-webkit-box",
                                              WebkitLineClamp: 1,
                                              WebkitBoxOrient: "vertical",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {item?.name}
                                          </p>
                                          {isCategory && (<p className='text-white text-sm mb-1'>{item?.count_cores || "0"} rutinas</p>)}
                                        </div>
                                        <div className='flex justify-center items-center h-full'>
                                          <div className='inline-flex h-full pl-3 pr-4'>
                                            <IconoIr fill={"#fff"} width={18} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                              {isCategory && (
                                <div className={`flex items-center ${(isEtapa1 || isNivel1) ? "justify-between" : "justify-center"} gap-6 px-6 pt-10 pb-6`}>
                                  <ButtonPrimary
                                    text={
                                      <div className='flex items-center'>
                                        <span className="d-block mr-3">
                                          <IconoBack fill={"#fff"} width={18} />
                                        </span>
                                        <p className='font-golos'>Volver</p>
                                      </div>
                                    }
                                    withIcon={false}
                                    classNameContainer="border-DeepKoamaru"
                                    classNameButton={"!bg-DeepKoamaru hover:!bg-[#1e114b] py-3 px-6"}
                                    onClick={() => navigate("/mi-cuenta/cores")}
                                  />
                                  {isNivel1 && (
                                    <ButtonPrimary
                                      text={
                                        <div className='flex items-center'>
                                          <p className='font-golos'>Ir a nivel 2</p>
                                          <span className="d-block ml-3">
                                            <IconoIr fill={"#fff"} width={18} />
                                          </span>
                                        </div>
                                      }
                                      withIcon={false}
                                      classNameContainer="border-DeepKoamaru"
                                      classNameButton={"!bg-DeepKoamaru hover:!bg-[#1e114b] py-3 px-6"}
                                      onClick={() => navigate(goToNivel2)}
                                    />
                                  )}
                                  {isEtapa1 && (
                                    <ButtonPrimary
                                      text={
                                        <div className='flex items-center'>
                                          <p className='font-golos'>Ir a etapa 2</p>
                                          <span className="d-block ml-3">
                                            <IconoIr fill={"#fff"} width={18} />
                                          </span>
                                        </div>
                                      }
                                      withIcon={false}
                                      classNameContainer="border-DeepKoamaru"
                                      classNameButton={"!bg-DeepKoamaru hover:!bg-[#1e114b] py-3 px-6"}
                                      onClick={() => navigate("/mi-cuenta/tu-programa")}
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <div className='flex flex-col justify-center text-center'>
                              <div className='font-golos-medium text-Crayola'>
                                <p>No se encontraron resultados, inténtalo de nuevo más tarde.</p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Cores;