import React, { useEffect, useMemo, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import StarIcon from '../../../assets/icons/icon-star.svg';
import { ButtonPrimary } from '../../../components/Button';
import intersectionObserver from '../../../utils/intersectionObserver';

const bgImage = require('../../../assets/images/bg_degraded/8.jpg');

const programMessages = {
  "fitmom-embarazo": [
    { text: "al programa más completo de ejercicios para embarazadas", className: "font-golos-medium mt-2" },
    { text: "Prueba 7 días gratis, cancela cuando quieras", className: "font-golos-semibold" },
  ],
  "fitmom-posparto": [
    { text: "al programa más completo de ejercicios posparto", className: "font-golos-medium mt-2" },
    { text: "Prueba 7 días gratis, cancela cuando quieras", className: "font-golos-semibold" },
  ],
  "fitmom-strength": [
    { text: "Obtén tu prueba gratis de 7 días, cancela cuando quieras", className: "font-golos-semibold mt-2" },
  ],
};

const programStyles = {
  "fitmom-embarazo": {
    bgButton: "!bg-OrchidHover",
    borderButton: "!border-Crayola",
    textColor: "text-Crayola",
    manualLink: "https://jimefitmom.vip/manual-del-ejercicio-seguro-en-el-embarazo",
  },
  "fitmom-posparto": {
    bgButton: "!bg-Crayola",
    borderButton: "!border-OrchidHover",
    textColor: "text-OrchidHover",
    manualLink: "https://jimefitmom.vip/manuales-posparto",
  },
  "fitmom-strength": {
    bgButton: "!bg-DeepKoamaru",
    borderButton: "!border-DeepKoamaru",
    textColor: "text-DeepKoamaru",
  },
};

const Membresias = ({ typeProgram, options, onSubscribe, rulesStart = [], rulesEnd = [] }) => {
  const elementsRef = useRef([]);

  const messages = useMemo(() => programMessages[typeProgram] || [], [typeProgram]);

  const {
    bgButton,
    borderButton,
    textColor,
    manualLink,
  } = useMemo(() => programStyles[typeProgram] || {}, [typeProgram]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div
      id={"programas"}
      className="bg-Orchid bg-[size:100%_100%] bg-[position:0_0] bg-no-repeat"
      style={{
        backgroundImage: `url(${bgImage})`,
        // backgroundBlendMode: "hard-light",
        backgroundColor: "var(--maskbg)"
      }}
    >
      <div
        className={`relative xl:container mx-auto px-6 pb-12 ${typeProgram === "fitmom-strength" ? "pt-12 sm:pt-16" : "pt-8 sm:pt-12"}`}
      >
        {messages.length > 0 && (
          <>
            <h2
              ref={(el) => elementsRef.current.push(el)}
              className="animate__animated font-bebas text-4xl xs:text-5xl lg:text-6xl text-DeepKoamaru text-center"
              data-animation="animate__fadeInLeft"
            >
              SUSCRÍBETE AHORA
            </h2>
            {messages.map((msg, index) => (
              <h2
                key={index}
                ref={(el) => elementsRef.current.push(el)}
                className={`animate__animated ${msg.className} text-md md:text-lg lg:text-xl text-DeepKoamaru text-center`}
                data-animation={index === 0 ? "animate__fadeInLeft" : "animate__fadeInRight"}
              >
                {msg.text}
              </h2>
            ))}
            {typeProgram === "fitmom-posparto" && (
              <div className='mt-5'>
                <p className='mx-auto max-w-[560px] font-golos text-DeepKoamaru text-center'>
                  Para obtener resultados más efectivos y garantizar una recuperación completa se recomienda seguir el programa de 6 a 12 meses (el tiempo que
                  le toma al cuerpo recuperarse por completo después de un embarazo).
                </p>
              </div>
            )}
          </>
        )}
        <div
          className={
            `flex flex-wrap gap-6 lg:gap-10 items-end justify-center ${typeProgram === "fitmom-posparto" ? "mt-6" : "mt-8 sm:mt-10 lg:mt-14"}`
          }
        >
          {/* Semestral */}
          <div className='mx-auto lg:mx-0'>
            <div
              className={`bg-white border-4 ${typeProgram === "fitmom-strength" ? "border-DeepKoamaru" : "border-Crayola"} mt-[4px] rounded-[1.6em] pb-5 text-center`}
            >
              <div
                className={`${typeProgram === "fitmom-strength" ? "bg-DeepKoamaru" : "bg-Crayola"} py-2 px-4 mb-6 rounded-t-[1.1em]`}
              >
                <div className='flex items-center justify-center gap-1 pr-4'>
                  <LazyLoadImage alt="icon" src={StarIcon} className='h-4 w-4' />
                  <p className='font-golos-semibold leading-6 text-white text-[1.1em]'>
                    Más vendido
                  </p>
                </div>
                <p className='font-golos-semibold leading-6 text-white text-xl'>AHORRA 50%</p>
              </div>
              <div className='px-4'>
                <p
                  ref={(el) => elementsRef.current.push(el)}
                  data-animation={"animate__fadeInDown"}
                  className={`animate__animated font-golos-semibold text-xl md:text-3xl lg:text-2xl text-center ${typeProgram === "fitmom-strength" ? "text-DeepKoamaru" : "text-Crayola"} mb-0`}
                  style={{ lineHeight: "0.9rem" }}
                >
                  Plan
                </p>
                <p
                  ref={(el) => elementsRef.current.push(el)}
                  data-animation={"animate__fadeInUp"}
                  className={`animate__animated font-golos-semibold text-xl md:text-3xl lg:text-3xl text-center ${typeProgram === "fitmom-strength" ? "text-DeepKoamaru" : "text-Crayola"} mb-0`}
                >
                  SEMESTRAL
                </p>
                <div className='px-4'>
                  <div className={`border ${borderButton} px-4 py-3 rounded-xl mt-4`}>
                    <p className={`font-golos-semibold ${textColor} text-sm`}>Prueba de 7 días gratis</p>
                  </div>
                </div>
                <p className='text-md text-DarkJungleGreen mt-4'>al terminar periodo:</p>
                <p className='font-golos-semibold text-xl md:text-2xl lg:text-3xl text-neutral-300 mt-2'>
                  <span className='line-through'>${(parseFloat(options?.price_month || 0) * 6)?.toFixed(2)}</span>
                  <sup className="font-golos-bold align-super text-[0.6rem] top-[-0.2em] pl-[0.15em]">USD</sup>
                </p>
                <p className={`font-golos-semibold text-3xl md:text-4xl lg:text-5xl ${typeProgram === "fitmom-posparto" ? "text-OrchidHover" : "text-Crayola"}`}>
                  ${parseFloat(options?.price_biannual || 0)?.toFixed(2)}
                  <sup className="font-golos-bold align-super text-xs top-[-0.4em] pl-[0.15em]">USD</sup>
                </p>
                <div className='mt-4 mx-auto max-w-[220px]'>
                  <p className='text-[0.5rem] font-golos-semibold text-DarkJungleGreen text-center mt-2'>
                    Este plan se renueva automáticamente cada 6 meses, Puedes cancelar en cualquier momento desde tu cuenta.
                  </p>
                </div>
                <div className='mt-4'>
                  <ButtonPrimary
                    text={"¡SUSCRÍBETE!"}
                    withIcon={false}
                    classNameButton={`${bgButton} px-6`}
                    classNameContainer={`flex justify-center ${borderButton}`}
                    onClick={() => onSubscribe("semestral")}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Mensual */}
          <div className='mx-auto lg:mx-0'>
            <div className='relative bg-white rounded-[1.6em] pt-8 pb-6 px-4 text-center'>
              <p
                ref={(el) => elementsRef.current.push(el)}
                data-animation={"animate__fadeInDown"}
                className={`animate__animated font-golos-semibold text-xl md:text-3xl lg:text-2xl text-center ${typeProgram === "fitmom-strength" ? "text-DeepKoamaru" : "text-Crayola"} mb-0`}
                style={{ lineHeight: "0.9rem" }}
              >
                Plan
              </p>
              <p
                ref={(el) => elementsRef.current.push(el)}
                data-animation={"animate__fadeInUp"}
                className={`animate__animated font-golos-semibold text-xl md:text-3xl lg:text-3xl text-center ${typeProgram === "fitmom-strength" ? "text-DeepKoamaru" : "text-Crayola"} mb-0`}
              >
                MENSUAL
              </p>
              <div className='px-4'>
                <div className={`border ${borderButton} px-4 py-3 rounded-xl mt-4`}>
                  <p className={`font-golos-semibold ${textColor} text-sm`}>Prueba de 7 días gratis</p>
                </div>
              </div>
              <p className='text-md text-DarkJungleGreen mt-5 mb-5'>al terminar periodo:</p>
              <p className={`font-golos-semibold text-3xl md:text-4xl lg:text-5xl ${typeProgram === "fitmom-posparto" ? "text-OrchidHover" : "text-Crayola"}`}>
                ${parseFloat(options?.price_month || 0)?.toFixed(2)}
                <sup className="font-golos-bold align-super text-xs top-[-0.4em] pl-[0.15em]">USD</sup>
              </p>
              <div className='mt-5 mx-auto max-w-[220px]'>
                <p className='text-[0.5rem] font-golos-semibold text-DarkJungleGreen text-center mt-2'>
                  Este plan se renueva automáticamente cada mes, Puedes cancelar en cualquier momento desde tu cuenta.
                </p>
              </div>
              <div className='mt-4'>
                <ButtonPrimary
                  text={"¡SUSCRÍBETE!"}
                  withIcon={false}
                  classNameButton={`${bgButton} px-6`}
                  classNameContainer={`flex justify-center ${borderButton}`}
                  onClick={() => onSubscribe("mensual")}
                />
              </div>
            </div>
          </div>

          {/* Manual */}
          {["fitmom-embarazo", "fitmom-posparto"].includes(typeProgram) && (
            <div className='self-center mx-auto lg:mx-0'>
              <div className='relative bg-white rounded-[1.6em] mt-[6px] pt-8 pb-6 px-6 text-center'>
                <div className='mb-4 min-w-[180px] max-w-[220px]'>
                  <p
                    ref={(el) => elementsRef.current.push(el)}
                    data-animation={"animate__fadeInDown"}
                    className='animate__animated font-golos-semibold text-lg lg:text-xl text-center text-Crayola mb-0'
                    style={{ lineHeight: "0.9rem" }}
                  >
                    MANUAL DIGITAL
                  </p>
                  {typeProgram !== "fitmom-posparto" && (
                    <p
                      ref={(el) => elementsRef.current.push(el)}
                      data-animation={"animate__fadeInUp"}
                      className='whitespace-break-spaces mt-2 animate__animated font-golos-semibold leading-5 text-lg lg:text-[1.2rem] text-center text-Crayola mb-0'
                    >
                      {`Ejercicio Seguro \nen Embarazo`}
                    </p>
                  )}
                  {typeProgram === "fitmom-posparto" && (
                    <p
                      ref={(el) => elementsRef.current.push(el)}
                      data-animation={"animate__fadeInUp"}
                      className='whitespace-break-spaces mt-2 animate__animated font-golos-semibold leading-5 text-lg lg:text-[1.2rem] text-center text-Crayola mb-0'
                    >
                      {`Recuperación Después \ndel Parto/Cesárea`}
                    </p>
                  )}
                </div>
                <p className='font-golos-semibold text-3xl md:text-4xl lg:text-5xl text-OrchidHover'>
                  $8.99
                  <sup className="font-golos-bold align-super text-xs top-[-0.4em] pl-[0.15em]">USD</sup>
                </p>
                <p className='text-md text-DarkJungleGreen mt-2 mb-5'>1 sólo pago</p>
                <div className='mt-4'>
                  <ButtonPrimary
                    text={"Adquirir Manual"}
                    withIcon={false}
                    classNameButton={"bg-OrchidHover text-white px-6"}
                    classNameContainer="flex justify-center !border-OrchidHover"
                    onClick={() => window.location.href = manualLink}
                  />
                </div>
                <div className='mt-5 mx-auto max-w-[220px]'>
                  <p className='text-[0.5rem] font-golos-semibold text-DarkJungleGreen text-center mt-2'>
                    Este no es un programa de entrenamiento
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {["fitmom-embarazo", "fitmom-posparto"].includes(typeProgram) && (
          <div className='mt-12'>
            <p className='font-golos-medium leading-6 text-DeepKoamaru text-xl text-center mt-2'>
              Con cualquier plan podrás cambiarte al programa
            </p>
            {typeProgram !== "fitmom-posparto" && (
              <p className='font-golos-medium leading-6 text-DeepKoamaru text-xl text-center'>
                posparto cuando lo necesites.
              </p>
            )}
            {typeProgram === "fitmom-posparto" && (
              <p className='font-golos-medium leading-6 text-DeepKoamaru text-xl text-center'>
                avanzado cuando estés lista.
              </p>
            )}
          </div>
        )}
        <div
          className={`flex flex-col items-center ${typeProgram === "fitmom-strength" ? "mb-6 mt-14" : "mt-10"}`}
        >
          <div className='bg-white inline-flex py-3 px-7 rounded-full'>
            <p className='text-DeepKoamaru text-center'>*Antes de comenzar cualquier rutina de ejercicio debes de obtener la aprobación de tu médico.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Membresias;