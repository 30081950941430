import React, {
  useCallback,
  useEffect,
  useRef
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as RegaloIcon } from '../../assets/icons/programas/posparto/icon-bono01.svg';
import bonoExtra1 from '../../assets/icons/programas/posparto/icon-bono02.svg';
import bonoExtra2 from '../../assets/icons/programas/posparto/icon-bono03.svg';
import bonoExtra3 from '../../assets/icons/programas/posparto/icon-bono04.svg';
import bonoExtra4 from '../../assets/icons/programas/posparto/icon-bono05.svg';
import bonoExtra5 from '../../assets/icons/programas/posparto/icon-bono06.svg';
import bonoExtra6 from '../../assets/icons/programas/posparto/icon-bono07.svg';
import HeaderNav from '../../components/Header';
import Footer from '../../components/Footer';
import SEO from '../../components/SEO';
import { usePromocion } from '../../components/PromocionProvider';
import VideoPreviewPlayer from '../../components/VideoPreviewPlayer';
import { getBannerPrograma, getProgramas } from '../../api/client/programas';
import { useCustomState } from '../../hooks/useCustomState';
import intersectionObserver from '../../utils/intersectionObserver';
import PreguntasFrecuentes from '../components/PreguntasFrecuentes';
import EntrenaApp from './components/EntrenaApp';
import Membresias from './components/Membresias';
import BenefitsGrid from './components/BenefitsGrid';
import BeneficiosButtonsCarousel from '../components/BeneficiosButtonsCarousel';
import BeneficiosProgramasCarousel from './components/BeneficiosProgramasCarousel';
import DisfrutaEnDispositivos from './components/DisfrutaEnDispositivos';
import TestimoniosProgramasCarousel from './components/TestimoniosProgramasCarousel';
import { benefitsPospartoData } from '../../misc/getBenefitsProgramData';
import { getPreguntasFrecuentesPosparto } from '../../misc/getPreguntasFrecuentes';
import { setSubscription } from '../../redux/reducers/subscriptionSlice';

const ProgramaPosparto = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carouselBeneficiosRef = React.createRef();
  const carouselTestimoniosRef = React.createRef();
  const elementsRef = useRef([]);

  const { state: statePromocion } = usePromocion();
  const [state, setState] = useCustomState({
    preguntasFrecuentes: [],
    isVideoVisible: false,
    portada_video_introduccion: null,
    video_introduccion: null,
    programaDetalles: null,
    bannerDetalles: null,
  });

  useEffect(() => {
    searchSuscripcionData();
    loadProgram();

    (async () => {
      setState({
        preguntasFrecuentes: await getPreguntasFrecuentesPosparto(),
      });
    })();

    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  const searchSuscripcionData = useCallback(async () => {
    // const _getVimeoAPI = async (id) => (await getDataVimeoApi(id))?.data;

    setState({
      portada_video_introduccion: "/assets/images/posparto_fitmom.jpg", // (await _getVimeoAPI(821854458))?.video?.thumbs?.base,
      video_introduccion: {
        url: "/assets/videos/posparto_fitmom.mp4",
      },
    });
  }, [setState]);

  const loadProgram = useCallback(async () => {
    try {
      const programasResponse = await getProgramas({ code: name });
      const programDetails = programasResponse?.data?.[0];

      let bannerResponse = null;
      if (programDetails?.id) {
        try {
          bannerResponse = await getBannerPrograma({ programID: programDetails.id });
        } catch (bannerError) { }
      }

      setState({
        programaDetalles: programDetails,
        bannerDetalles: bannerResponse?.data || { image: require('../../assets/images/programas/banners/banner-posparto.jpg') },
      });
    } catch (error) {
      setState({
        bannerDetalles: { image: require('../../assets/images/programas/banners/banner-posparto.jpg') },
      });
    }
  }, [name, setState]);

  const seleccionarSuscripcion = useCallback((plan, extraParams = {}) => {
    dispatch(setSubscription({
      data: {
        id: state.programaDetalles?.id,
        tipo_programa: "Programa de entrenamiento",
        code: state.programaDetalles?.code,
        nombre: state.programaDetalles?.name,
        plan,
        precio: plan === "mensual" ? state.programaDetalles?.cost_month : state.programaDetalles?.cost_biannual,
      }
    }));

    const params = new URLSearchParams(extraParams).toString();
    const url = params ? `/carrito/posparto-${plan}?${params}` : `/carrito/posparto-${plan}`;

    navigate(url);
  }, [state.programaDetalles, dispatch, navigate]);

  return (
    <>
      <SEO
        title="Programa de entrenamiento"
        description="Para mujeres de 0 a 12 meses postparto/postcesárea o mujeres que desean corregir disfunciones."
      />

      <div className=''>
        {/* Formato de imagenes */}
        {/* <div
          className={`bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center transition-all ${statePromocion.isActive ? "mt-[3.1rem]" : ""}`}
          style={{
            ...(state.bannerDetalles?.image ? { backgroundImage: `url(${state.bannerDetalles.image})` } : {}),
            paddingTop: "calc(720 / 1920 * 100%)"
          }}
        >
          <HeaderNav />
        </div> */}

        {/* Formato de videos */}
        <div
          className={`relative overflow-hidden bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center transition-all ${statePromocion.isActive ? "mt-[3.1rem]" : ""}`}
          style={{
            paddingTop: "calc(1080 / 1920 * 100%)"
          }}
        >
          <VideoPreviewPlayer
            playerContainerClassName="absolute top-0 left-0 w-full h-full pointer-events-none z-1"
            hasOverlay={false}
            autoplay={true}
            loop={true}
            controls={false}
            altText='Video posparto'
            videoSrc={require("../../assets/videos/programas/VIDEO-Posparto.mp4")}
          />
          <HeaderNav />
        </div>

        <div
          className='bg-LavenderBlush bg-left lg:bg-center bg-cover bg-no-repeat w-full'
          style={{
            backgroundImage: `url("${require("../../assets/images/bg_degraded/4.jpg")}")`
          }}
        >
          <div className="xl:container mx-auto px-6 max-w-6xl">
            <div className='grid sm:grid-cols-2 gap-4'>
              <div className='order-2 sm:order-1 max-w-[520px] mx-auto pt-[2rem] sm:pt-[4.5rem] sm:pb-[4.5rem] pb-[2rem] text-center sm:text-left'>
                <h1
                  ref={(el) => elementsRef.current.push(el)}
                  className='animate__animated font-golos-semibold text-xl lg:text-2xl text-Crayola'
                  data-animation={"animate__fadeInDown"}
                >
                  Programa de entrenamiento
                </h1>
                <h1
                  ref={(el) => elementsRef.current.push(el)}
                  className='animate__animated font-bebas text-4xl md:text-5xl lg:text-7xl text-OrchidHover'
                  data-animation={"animate__fadeInUp"}
                >
                  FITMOM POSPARTO
                </h1>
                <p
                  className='font-golos-medium text-md lg:text-md text-DeepKoamaru mt-2'
                >
                  Programa de ejercicios para la recuperación después del parto o la cesárea y ejercicios para corregir diástasis, incontinencia, vientre abultado (mommy pooch), dolor de espalda o dolor pélvico.
                </p>
              </div>
              <div className='order-1 sm:order-2 relative'>
                <div className='-my-[2rem] sm:my-0 sm:absolute sm:top-0 sm:translate-y-[-45%] group-hover:translate-y-0 group-hover:my-0 group-hover:relative transition-transform duration-300'>
                  <LazyLoadImage
                    alt="App image"
                    src={require("../../assets/images/programas/img-app-posparto.png")}
                    className='mx-auto max-w-[220px] sm:mx-none xs:max-w-[300px] sm:max-w-[420px] w-full'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white pt-6 md:pt-16 pb-16 w-full'>
        <div className='xl:container mx-auto px-6 max-w-6xl'>
          <h1
            ref={(el) => elementsRef.current.push(el)}
            className='animate__animated font-bebas text-3xl md:text-4xl lg:text-5xl text-center text-OrchidHover mb-8'
            data-animation={"animate__fadeInDown"}
          >
            CON ESTE PROGRAMA PODRÁS:
          </h1>
          <BenefitsGrid
            benefits={benefitsPospartoData}
            program="posparto"
          />
          <div className='mt-8 xs:px-6 sm:px-12'>
            <VideoPreviewPlayer
              hasOverlay={false}
              playerContainerClassName='rounded-2xl'
              altText='Thumbnail video posparto'
              thumbnail={require("../../assets/images/programas/video-posparto.jpg")}
              videoSrc={require("../../assets/videos/video-posparto.mp4")}
            />
          </div>
        </div>
      </div>

      <div
        className='bg-LavenderBlush w-full overflow-x-hidden'
      >
        <div className="xl:container mx-auto px-6">
          <div className='max-w-[760px] mx-auto'>
            <div ref={(el) => elementsRef.current.push(el)} className='animate__animated py-16' data-animation={"animate__fadeInLeft"}>
              <h1 className='leading-7 font-golos-semibold text-OrchidHover text-center text-lg sm:text-xl md:text-2xl mb-6'>
                ¿Te sientes débil, sin energía y ajena en tu nuevo cuerpo posparto, y te preguntas cuándo y con qué ejercicios debes empezar para recuperar tu cuerpo de forma efectiva?
              </h1>
              <div className='flex mx-auto max-w-lg mb-5'>
                <LazyLoadImage
                  alt="cover image"
                  src={require("../../assets/images/programas/img-jimefit-2.png")}
                  className='object-contain w-full h-full'
                />
              </div>
              <div className='flex flex-col gap-6 mb-8'>
                <div className='max-w-[620px] mx-auto'>
                  <p className='font-golos leading-5 text-sm sm:text-md text-DeepKoamaru text-center'>
                    Como mamá de 3 sé lo difícil que puede llegar a ser el posparto: no solo tu cuerpo cambia, también las emociones, la rutina y el tiempo que tienes disponible para ti.
                  </p>
                </div>
                <p className='font-golos leading-5 text-sm sm:text-md text-DeepKoamaru text-center'>
                  Quizás te han dicho que no debes hacer ningún ejercicio durante los primeros 40 días, pero la realidad es que después de tener un bebé vas a enfrentarte a diferentes movimientos desde el día 1 como levantarte y girar en tu cama, levantar a tu bebé de su cuna, empujar una carriola, agacharte a recoger cosas de piso, etc. e incluir ejercicios gentiles de respiración, suelo pélvico y core profundo desde los primeros días posparto te puede ayudar mucho a acelerar tu recuperación y a realizar tus movimientos de forma más segura y con menos dolor. Dentro de mi programa te iré guiando paso a paso por este proceso, con ejercicios seguros y respaldados por la ciencia para garantizar una recuperación efectiva.
                </p>
              </div>
              <div className='flex justify-center'>
                <a href="#programas" className='inline-flex font-golos-semibold bg-OrchidHover text-white rounded-full py-4 px-5 no-underline text-sm text-center'>
                  PRUEBA AHORA MIS 7 DÍAS GRATIS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Membresias
        typeProgram={state.programaDetalles?.code}
        onSubscribe={seleccionarSuscripcion}
        options={{
          price_month: state.programaDetalles?.cost_month,
          price_biannual: state.programaDetalles?.cost_biannual,
        }}
      />

      <EntrenaApp program="posparto" />

      <div className='pt-20 pb-16 bg-white'>
        <div className="xl:container mx-auto px-6">
          <div className='mx-auto max-w-4xl sm:px-5'>
            <h1
              ref={(el) => elementsRef.current.push(el)}
              className='font-bebas text-OrchidHover text-center text-4xl md:text-[3.8rem] mb-12 px-4 animate__animated'
              data-animation={"animate__fadeInDown"}
            >
              EL PROGRAMA ESTÁ DIVIDIDO EN 2 ETAPAS:
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 items-start gap-10'>
              <div className='flex flex-col gap-3 text-center md:text-left'>
                <p className='font-golos-semibold text-2xl text-DeepKoamaru'>
                  ETAPA 1: CORE REHAB
                </p>
                <p className='font-golos text-sm text-DeepKoamaru'>
                  Durante 8 semanas te acompañare con ejercicios iniciales para tu rehabilitación y recuperación. Esta etapa se puede iniciar tan pronto como el día 1 después del parto o la cesárea.
                </p>
                <LazyLoadImage
                  alt="Etapa 1 App"
                  src={require("../../assets/images/programas/etapa1-app.png")}
                  className='mt-4 w-full'
                />
              </div>
              <div className='flex flex-col gap-3 text-center md:text-left'>
                <p className='font-golos-semibold text-2xl text-DeepKoamaru'>
                  ETAPA 2: STRENGTH
                </p>
                <p className='font-golos text-sm text-DeepKoamaru'>
                  A partir de la semana 9 te acompañaré en la etapa de fortalecimiento con entrenamientos semanales de fuerza, cardio, yoga, pilates y barre postnatales para incrementar tu fuerza, rendimiento, movilidad y flexibilidad.
                </p>
                <LazyLoadImage
                  alt="Etapa 2 App"
                  src={require("../../assets/images/programas/etapa2-app.png")}
                  className='mt-4 w-full'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-10 pb-16 bg-LavenderBlush overflow-hidden'>
        <div className="xl:container mx-auto px-10">
          <div
            ref={(el) => elementsRef.current.push(el)}
            data-animation={"animate__fadeInDown"}
            className='animate__animated flex flex-col sm:flex-row items-center gap-6 mt-10 mb-10'
          >
            <div className='flex-1'>
              <h1 className='max-w-[520px] font-golos-semibold text-DeepKoamaru text-center sm:text-left text-2xl whitespace-break-spaces'>
                {`Esto es lo que incluye tu suscripción \nal programa de ejercicio Fitmom Posparto.`}
              </h1>
            </div>
            <BeneficiosButtonsCarousel carouselRef={carouselBeneficiosRef} />
          </div>
          <BeneficiosProgramasCarousel
            customRef={carouselBeneficiosRef}
            program="posparto"
          />
          <div className='sm:mb-8 mt-14 sm:mt-20'>
            <div className='flex flex-col md:flex-row items-center justify-center gap-4'>
              <RegaloIcon height={48} width={48} />
              <h1 className='font-bebas text-OrchidHover text-center text-4xl md:text-5xl lg:text-6xl'>
                ADEMÁS LLÉVATE ESTOS BONOS EXTRA:
              </h1>
            </div>
            <div className='mt-8 sm:mt-16 flex flex-wrap items-center justify-center gap-10 sm:gap-12 max-w-[660px] lg:max-w-[960px] w-full mx-auto'>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra1}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Guía nutricional para \nreducir inflamación`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra2}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Manual de recuperación \ndespués del parto \nvaginal/Cesárea`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra3}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Guía para \nconstruir hábitos`}
                </p>
              </div>

              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra4}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Tarjetas digitales de \nafirmaciones para el \nposparto`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra5}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Descuentos \ncon partners`}
                </p>
              </div>
              <div className='flex items-center justify-center gap-5 md:gap-4 w-full sm:w-auto'>
                <LazyLoadImage
                  alt="icon"
                  src={bonoExtra6}
                  className='h-[52px] w-[52px]'
                />
                <p className='flex-1 font-golos-medium text-DeepKoamaru text-lg sm:whitespace-break-spaces'>
                  {`Guía para el sueño \ndel recién nacido`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-16 bg-white'>
        <div className="xl:container mx-auto px-10">
          <div className='grid grid-cols-1 sm:grid-cols-[1.3fr_2fr] items-center gap-10'>
            <div className='max-w-[460px] mx-auto'>
              <p className='font-golos-semibold leading-7 text-OrchidHover text-2xl xs:text-3xl md:text-4xl text-center sm:text-left'>
                +15,000 mamás
              </p>
              <p className='font-golos leading-7 text-OrchidHover text-2xl xs:text-3xl md:text-4xl text-center sm:text-left'>
                ya han tomado mis programas y experimentado los beneficios.
              </p>
            </div>
            <div>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/img-programas-2.png")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#fbf5fa]'>
        <div className="xl:container mx-auto px-6 max-w-6xl">
          <div className='grid grid-cols-1 sm:grid-cols-[1.1fr_1.6fr] items-center gap-10'>
            <div className='mx-auto max-w-[260px] sm:max-w-[360px]'>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/img-jimecert.png")}
              />
            </div>
            <div className='sm:py-8 mb-10 sm:mb-0 space-y-7 text-center sm:text-left'>
              <h3 className='font-bebas text-3xl sm:text-5xl text-DeepKoamaru'>JIMENA CANTÚ</h3>
              <p className='font-golos-bold text-md'>
                Soy mamá de 3 niñas, Fitness Coach Certificada en Ejercicio Pre y Posnatal, Especialista en Ejercicios Correctivos, Suelo Pélvico y Core por Fit For Birth y AFPA.
              </p>
              <p className='font-golos text-md'>
                Con mis programas de entrenamiento he podido ayudar a más de 15,000 mamás a tener embarazos y pospartos más saludables, mejorando su experiencia de parto y recuperación. Mi misión es educar y empoderar a las mamás, proporcionándoles herramientas para que se sientan seguras y confiadas con su cuerpo y su maternidad.
              </p>
              <div className='mx-auto sm:mx-none max-w-[420px] pt-3'>
                <p className='font-golos-bold text-md text-Crayola'>
                  Recomendada por ginecólogos, doulas y fisioterapeutas en Estados Unidos, México y Latinoamérica.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='bg-LavenderBlush bg-center bg-[size:100%_100%] bg-no-repeat w-full'
        style={{
          backgroundImage: `url("${require("../../assets/images/bg_degraded/4.jpg")}")`
        }}
      >
        <div className="xl:container mx-auto sm:mr-none xl:ml-auto xl:pl-16">
          <div className='grid grid-cols-1 sm:grid-cols-[1fr_1fr] lg:grid-cols-[1fr_2fr] items-center gap-3'>
            <div className='mx-auto lg:ml-auto 2xl:mx-auto max-w-[420px] sm:max-w-[400px] px-10'>
              <h4 className='font-golos text-4xl md:text-[2.6rem] md:leading-[2.7rem] text-DeepKoamaru mt-10 text-center sm:text-left lg:whitespace-break-spaces'>
                <span className='font-golos-semibold'>Comienza hoy </span>
                <span>{`\na rehabilitar \ntu cuerpo y \nrecuperar tu \nfuerza`}</span>
              </h4>
            </div>
            <div className='mx-auto'>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/banner3-img.png")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white w-full'>
        <div className="xl:container mx-auto px-8 lg:px-16">
          <div className='grid grid-cols-1 lg:grid-cols-[1.5fr_1.3fr] items-center gap-10'>
            <div className='lg:pl-16 lg:pr-8 py-16 lg:py-0 space-y-6 text-center lg:text-left'>
              <h4 className='font-bebas text-[2.5rem] leading-[2.5rem] lg:text-[2.8rem] lg:leading-[2rem] text-OrchidHover'>
                ¿ESTE PROGRAMA ES PARA MI?
              </h4>
              <p className='text-md'>
                <span className='font-golos-semibold text-OrchidHover'>Fitmom Posparto</span> esta diseñado para cualquier mujer que haya pasado por un parto o una cesárea y quiera recuperar la fuerza y función abdominal y de su suelo pélvico. Y si ya tienes varios meses o incluso años posparto pero no has podido corregir tu diástasis, incontinencia o dolores de espalda, entonces este programa también es para ti. Nunca es demasiado tarde
              </p>
              <p className='text-md font-golos-bold text-DeepKoamaru'>
                Después de tener hijos nuestro cuerpo ¡Siempre estará en posparto!
              </p>
              <div>
                <a href="#programas" className='mt-5 inline-flex bg-OrchidHover font-golos-semibold text-white py-3 px-10 rounded-full'>
                  UNIRME A LOS 7 DÍAS GRATIS
                </a>
              </div>
            </div>
            <div className='hidden lg:block max-w-[380px] mr-auto mt-6'>
              <LazyLoadImage
                alt="photo preview"
                src={require("../../assets/images/programas/img-esparami-2.png")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='py-12 bg-[#eae9f7]'>
        <div className="xl:container mx-auto px-6 mb-12 overflow-x-hidden">
          <div
            ref={(el) => elementsRef.current.push(el)}
            data-animation={"animate__fadeInDown"}
            className='animate__animated flex flex-row items-center gap-6 mt-10 mb-12 md:mr-16'
          >
            <div className='flex-1'>
              <h1 className='max-w-[520px] font-golos-bold text-DeepKoamaru text-left text-2xl'>
                Testimonios de clientes felices
              </h1>
              <p className='max-w-[520px] text-DeepKoamaru text-left text-sm'>
                Comparten el progreso en 8 semanas de haber iniciado el programa
              </p>
            </div>
            <BeneficiosButtonsCarousel carouselRef={carouselTestimoniosRef} />
          </div>
          <TestimoniosProgramasCarousel
            customRef={carouselTestimoniosRef}
            program="posparto"
          />
        </div>
      </div>

      <DisfrutaEnDispositivos program="posparto" />

      <div className='py-16 bg-[#fbf5fa]'>
        <div className="xl:container mx-auto px-6">
          <div className='mt-4 mb-10'>
            <h1
              ref={(el) => elementsRef.current.push(el)}
              className='font-bebas text-DeepKoamaru text-center text-[2.4rem] animate__animated'
              data-animation={"animate__slideInDown"}
            >
              ¿AÚN TE QUEDAN DUDAS?
            </h1>
            <p className='font-golos-medium text-Crayola text-lg text-center'>
              No te preocupes, nosotras te respondemos
            </p>
          </div>
          <div className='mx-auto max-w-[50rem]'>
            <PreguntasFrecuentes
              data={state.preguntasFrecuentes}
            />
          </div>
          <div className='flex items-center justify-center mt-12 mb-5'>
            <div className='bg-Crayola py-3.5 px-12 rounded-full inline-flex'>
              <p className='font-golos-semibold leading-7 text-AntiFlashWhite text-center'>
                ¿TE QUEDARON DUDAS?, ESCRIBE A <a href='mailto:hola@jimefitmom.com' className='font-golos-semibold text-none'>HOLA@JIMEFITMOM.COM</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProgramaPosparto;