import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ReactComponent as AccesoIcon } from '../assets/icons/promotores/icon01.svg';
import { ReactComponent as ComunidadIcon } from '../assets/icons/promotores/icon02.svg';
import { ReactComponent as DescuentosIcon } from '../assets/icons/promotores/icon03.svg';
import { ReactComponent as Perfil01Icon } from '../assets/icons/promotores/icon-perfil01.svg';
import { ReactComponent as Perfil02Icon } from '../assets/icons/promotores/icon-perfil02.svg';
import { ReactComponent as Perfil03Icon } from '../assets/icons/promotores/icon-perfil03.svg';
import { ReactComponent as Perfil04Icon } from '../assets/icons/promotores/icon-perfil04.svg';
import { sendMailJoinPromoterProgram, sendMailPromoterQuestion } from '../api/contacto';
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import ScrollToAnchor from '../components/ScrollToAnchor';
import { optionsToast } from '../config/toast';
import { useCustomState } from '../hooks/useCustomState';
import intersectionObserver from '../utils/intersectionObserver';
import parseErrorMessage from '../utils/parseErrorMessage';
import { isEmail, isEmpty } from '../utils';

const Promotores = () => {
  const elementsRef = useRef([]);

  const [state, setState] = useCustomState({
    bgImageHeader: `url("${require("../assets/images/promotores/header-imgback.jpg")}")`,
    bgImageWhyJoinPartners: `url("${require("../assets/images/promotores/backimg01.jpg")}")`,
    bgImageJoinPartners: `url("${require("../assets/images/promotores/backimg02.jpg")}")`,
    bgImageProfile: `url("${require("../assets/images/promotores/backimg03.jpg")}")`,
    sending_form_programa: false,
    sending_form_pregunta: false,
    joinProgramForm: {
      nombre: "",
      email: "",
      profesion: "",
      direccion_negocio: "",
      website: "",
      facebook: "",
      instagram: "",
      youtube: "",
      tiktok: "",
    },
    questionForm: {
      question: "",
      email: "",
    }
  });

  const communityData = useMemo(() => [
    {
      text: (
        <>
          ¡Sé Parte de
          <br />
          Nuestra <span className='font-bold'>Comunidad!</span>
        </>
      ),
    },
    {
      image: require("../assets/images/promotores/comunidad/foto01.png"),
    },
    {
      image: require("../assets/images/promotores/comunidad/foto02.png"),
    },
    {
      image: require("../assets/images/promotores/comunidad/foto03.png"),
    },
    {
      image: require("../assets/images/promotores/comunidad/foto04.png"),
    },
    {
      image: require("../assets/images/promotores/comunidad/foto05.png"),
    },
    {
      image: require("../assets/images/promotores/comunidad/foto06.png"),
    },
    {
      text: (
        <>
          <span className='font-bold'>Transforma</span> la vida de tus pacientes mientras disfrutas de <span className='font-bold'>beneficios exclusivos</span> diseñados para ti.
        </>
      ),
    },
  ], []);

  const promoData = useMemo(() => [
    {
      title: 'Acceso gratuito',
      description: 'Acceso gratuito a programas JimeFitMom para el afiliado',
      icon: <AccesoIcon height={60} width={60} />,
    },
    {
      title: 'Comunidad',
      description: 'Se parte de la comunidad JIMEFITMOM liderada por Jime, experta en fitness pre y posnatal y recomendada por ginecólogos, fisioterapeutas, doulas y expertos en salud pre y posnatal.',
      icon: <ComunidadIcon height={60} width={60} />,
    },
    {
      title: 'Descuentos',
      description: 'Recibe tu cupón exclusivo del 30% de descuento en los programas JIMEFITMOM para tus pacientes o clientes.',
      icon: <DescuentosIcon height={60} width={60} />,
    },
  ], []);

  const profileData = useMemo(() => [
    {
      icon: <Perfil01Icon height={"100%"} width={"100%"} />,
      title: "Nutriólogos",
      description:
        "Profesionales en nutrición que buscan complementar sus planes con los programas de JimeFitMom.",
    },
    {
      icon: <Perfil02Icon height={"100%"} width={"100%"} />,
      title: "Fitness Coaches",
      description:
        "Entrenadores personales o instructores que desean integrar rutinas innovadoras para sus clientes en embarazo y posparto.",
    },
    {
      icon: <Perfil03Icon height={"100%"} width={"100%"} />,
      title: "Profesionales de la salud",
      description:
        "Ginecólogos, fisioterapeutas, y doulas interesados en fomentar el ejercicio especializado para un embarazo y posparto saludables.",
    },
    {
      icon: <Perfil04Icon height={"100%"} width={"100%"} />,
      title: "Aliados estratégicos",
      description:
        "Especialistas en bienestar físico y mental que quieran colaborar con JimeFitMom y su comunidad.",
    },
  ], []);

  const message = useCallback((title, message) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: title,
      detail: message,
    });
  }, []);

  const handleChangeJoinProgramForm = useCallback((e) => {
    const { name, value } = e.target;
    setState({ [name]: value }, 'joinProgramForm');
  }, [setState]);

  const handleChangeQuestionForm = useCallback((e) => {
    const { name, value } = e.target;
    setState({ [name]: value }, 'questionForm');
  }, [setState]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isEmpty(state.joinProgramForm?.nombre)) return message(null, "Por favor, ingresa tu nombre antes de continuar.");
    if (isEmpty(state.joinProgramForm?.email)) return message(null, "Por favor, ingresa tu correo electrónico antes de continuar.");
    if (!isEmail(state.joinProgramForm?.email)) return message(null, "El correo electrónico ingresado no es válido. Por favor, verifica que tenga el formato correcto.");

    try {
      setState({ sending_form_programa: true });

      const response = await sendMailJoinPromoterProgram(state.joinProgramForm);

      setState({ sending_form_programa: false });

      if (response?.status === true) {
        message('Solicitud enviada con éxito', '¡Felicidades! Tu solicitud para unirte al programa Fitmom Partners ha sido enviada. Nos pondremos en contacto pronto.');
        setState({
          nombre: "",
          email: "",
          profesion: "",
          direccion_negocio: "",
          website: "",
          facebook: "",
          instagram: "",
          youtube: "",
          tiktok: "",
        }, 'joinProgramForm');
      } else {
        message('Error al enviar la solicitud', parseErrorMessage(response) || 'Hubo un error al enviar tu solicitud. Por favor, intenta nuevamente más tarde.');
      }
    } catch (error) {
      setState({ sending_form_programa: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Hubo un problema al enviar tu solicitud. Intenta nuevamente más tarde.');
    }
  }, [state.joinProgramForm, message, setState]);

  const handleSendQuestion = useCallback(async () => {
    if (isEmpty(state.questionForm?.question)) return message(null, "Por favor, ingresa una pregunta antes de enviarla.");
    if (isEmpty(state.questionForm?.email)) return message(null, "Por favor, ingresa tu correo electrónico antes de continuar.");
    if (!isEmail(state.questionForm?.email)) return message(null, "El correo electrónico ingresado no es válido. Por favor, verifica que tenga el formato correcto.");

    try {
      setState({ sending_form_pregunta: true });

      const response = await sendMailPromoterQuestion(state.questionForm);

      setState({ sending_form_pregunta: false });

      if (response?.status === true) {
        message('Pregunta enviada', '¡Tu pregunta ha sido enviada con éxito! Nos pondremos en contacto contigo pronto para responderla.');
        setState({
          question: "",
          email: ""
        },
          'questionForm'
        );
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al enviar tu pregunta. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending_form_pregunta: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Hubo un problema al enviar tu pregunta. Intenta nuevamente más tarde.');
    }
  }, [state.questionForm, message, setState]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ScrollToAnchor />
      <SEO
        title="Fitmom Partners"
        description="Un programa exclusivo para profesionales de la salud, nutriólogos y fitness coaches que desean transformar vidas con JimeFitmom."
      />
      <HeaderNav theme="white" classNames={{ containerNav: "bg-transparent" }} />

      <LazyLoadComponent>
        <div
          className={`bg-[#f0e2ed] bg-cover bg-center bg-no-repeat pt-[6rem] w-full animate__animated animate__fadeIn`}
          style={{
            backgroundImage: state.bgImageHeader
          }}
        >
          <div className='xl:container mx-auto px-6'>
            <div className='grid grid-cols-1 sm:grid-cols-[2fr_1.6fr] gap-8 mb-7'>
              <div className='text-center sm:text-left'>
                <div className='sm:pl-[2rem] lg:pl-[3.5rem] mt-6 sm:mt-16'>
                  <h1 className='font-raleway font-regular text-DeepKoamaru text-3xl sm:text-4xl md:text-5xl xl:text-[3.5rem] xl:leading-[1] mb-4'>
                    <p className='sm:mb-1 lg:mb-3'>Únete a</p>
                    <p className='font-raleway font-bold'>Fitmom Partners</p>
                  </h1>
                  <div className='mb-16 mx-auto sm:mx-none max-w-[480px] xl:max-w-[540px]'>
                    <p className='font-raleway text-sm sm:text-[1rem] md:text-lg xl:text-xl text-DeepKoamaru leading-6'>
                      Un programa exclusivo para profesionales de la salud, nutriólogos y fitness coaches que desean transformar vidas con JimeFitmom.
                    </p>
                  </div>
                  <a
                    href="#unete-programa"
                    className='font-raleway font-bold bg-[#e5b1da] text-white py-3 px-8 rounded-xl'
                  >
                    Aplica ahora
                  </a>
                </div>
              </div>
              <div>
                <LazyLoadImage
                  alt="Foto Jimena"
                  src={require("../assets/images/promotores/header-img.png")}
                  className='mx-auto max-w-[320px] lg:max-w-[480px] w-full'
                />
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div
          className='py-12 md:py-16 bg-Magnolia bg-cover bg-center bg-no-repeat -mb-[2px]'
          style={{
            backgroundImage: state.bgImageWhyJoinPartners
          }}
        >
          <div className="xl:container mx-auto px-6">
            <div className='max-w-4xl mx-auto'>
              <h2 className='font-raleway font-semibold text-2xl sm:text-3xl md:text-[2.6rem] leading-7 md:leading-10 mb-1 lg:mb-3 text-DeepKoamaru text-center'>
                ¿Por qué unirte a <span className='text-OrchidHover'>Fitmom Partners?</span>
              </h2>
              <p className='font-raleway font-semibold text-md md:text-xl text-Crayola text-center'>
                Beneficios para los Afiliados
              </p>
              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 lg:gap-10 mt-10 lg:mt-14'>
                {promoData.map((item, index) => (
                  <PromoCard key={`promo-key-${index}`} {...item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div className='py-6 bg-[#c6bfdd]'>
          <div className="xl:container mx-auto px-6">
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5'>
              {communityData.map((item, index) => (
                <ComunidadContent key={`community-key-${index}`} {...item} />
              ))}
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div
          className={`pt-16 pb-6 bg-white bg-no-repeat lg:bg-cover lg:bg-[size:100%_100%] lg:bg-[position:bottom_left]`}
          style={{
            backgroundImage: state.bgImageProfile ? state.bgImageProfile : 'none',
          }}
        >
          <div className="xl:container mx-auto px-6">
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div>
                <h3 className='font-raleway font-semibold text-4xl leading-[1.25] text-DeepKoamaru text-center md:text-right'>
                  <span className='text-[#7672b1]'>¿Eres el</span> <br className='hidden md:block' /> perfil ideal?
                </h3>
                <LazyLoadImage
                  alt="Image profile"
                  src={require("../assets/images/promotores/img-perfil.png")}
                  className='lg:w-[130%] lg:max-w-none'
                  wrapperClassName='!hidden md:!flex w-full md:-mb-[18%] md:translate-y-[-15%] md:translate-x-[12%] pointer-events-none'
                />
              </div>
              <div className='flex flex-col md:mt-4'>
                {profileData.map((item, index) => (
                  <ProfileCard key={`profile-key-${index}`} {...item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div
          id="unete-programa"
          className={`-mt-[1px] py-14 bg-[#ded0e7] bg-no-repeat lg:bg-cover lg:bg-[size:100%_100%] lg:bg-[position:bottom_left]`}
          style={{
            backgroundImage: state.bgImageJoinPartners ? state.bgImageJoinPartners : 'none',
          }}
        >
          <div className="xl:container mx-auto px-6">
            <h2 className='font-raleway font-regular text-2xl sm:text-3xl md:text-4xl leading-7 md:leading-10 mb-1 lg:mb-2 text-DeepKoamaru text-center'>
              Aplica para unirte al programa <span className='text-DeepKoamaru font-bold'>Fitmom Partners</span>
            </h2>
            <p className='font-raleway font-semibold text-md sm:text-lg md:text-xl text-DeepKoamaru text-center'>
              y obten los beneficios
            </p>

            <div className='mx-auto max-w-5xl bg-gradient-to-r from-[#eccde8] to-[#bcbbd8] via-[#e2c9e5] from-20% via-30% mt-6 p-4'>
              <form
                onSubmit={handleSubmit}
                className='bg-gradient-to-r from-[#f4e6f4] to-[#dfdeed] via-[#f0e3f2] from-20% via-30% shadow-[0_2px_10px_rgba(150,150,150,0.4)] rounded-xl p-6 py-8 md:p-10'
              >
                <div className='grid grid-cols-1 gap-6'>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="nombre"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        Nombre:
                      </label>
                      <input
                        id="nombre"
                        type="text"
                        autoComplete="name"
                        name="nombre"
                        placeholder='...'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.nombre || ""}
                        onChange={handleChangeJoinProgramForm}
                        required={true}
                      />
                    </div>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="email"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        Correo electrónico:
                      </label>
                      <input
                        id="email"
                        type="email"
                        autoComplete="email"
                        name="email"
                        placeholder='email@example.com'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.email || ""}
                        onChange={handleChangeJoinProgramForm}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="profesion"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        Profesión:
                      </label>
                      <input
                        id="profesion"
                        type="text"
                        name="profesion"
                        placeholder='Fitness Coach Certificada Pre y Posnatal'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.profesion || ""}
                        onChange={handleChangeJoinProgramForm}
                      />
                    </div>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="direccion_negocio"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        Dirección de negocio:
                      </label>
                      <input
                        id="direccion_negocio"
                        type="text"
                        autoComplete="street-address"
                        name="direccion_negocio"
                        placeholder='Calle Ejemplo 123, Col. Fit, Ciudad Salud'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.direccion_negocio || ""}
                        onChange={handleChangeJoinProgramForm}
                      />
                    </div>
                  </div>
                  <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                    <label
                      htmlFor="website"
                      className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                    >
                      Website:
                    </label>
                    <input
                      id="website"
                      type="text"
                      autoComplete='url'
                      name="website"
                      placeholder='www.jimefitmom.com'
                      className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                      value={state.joinProgramForm?.website || ""}
                      onChange={handleChangeJoinProgramForm}
                    />
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="facebook"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        Facebook:
                      </label>
                      <input
                        id="facebook"
                        type="text"
                        name="facebook"
                        placeholder='Jime Fit Mom'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.facebook || ""}
                        onChange={handleChangeJoinProgramForm}
                      />
                    </div>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="instagram"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        Instagram:
                      </label>
                      <input
                        id="instagram"
                        type="text"
                        name="instagram"
                        placeholder='@jimefitmom'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.instagram || ""}
                        onChange={handleChangeJoinProgramForm}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="youtube"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        Youtube:
                      </label>
                      <input
                        id="youtube"
                        type="text"
                        name="youtube"
                        placeholder='@jimefitmom'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.youtube || ""}
                        onChange={handleChangeJoinProgramForm}
                      />
                    </div>
                    <div className='bg-white rounded-lg py-2 px-3 flex flex-col'>
                      <label
                        htmlFor="tiktok"
                        className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                      >
                        TikTok:
                      </label>
                      <input
                        id="tiktok"
                        type="text"
                        name="tiktok"
                        placeholder='@jimefitmom'
                        className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-md text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                        value={state.joinProgramForm?.tiktok || ""}
                        onChange={handleChangeJoinProgramForm}
                      />
                    </div>
                  </div>
                  <div className='flex items-center justify-center'>
                    <button
                      type="submit"
                      className='font-raleway font-bold inline-flex bg-[#e3b0dc] text-white py-2 px-6 rounded-xl min-w-[156px]'
                    >
                      {state.sending_form_programa ? <ProgressSpinner strokeWidth='3' style={{ height: 22, width: 22 }} /> : "Enviar solicitud"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div
          className={`py-4 bg-white`}
        >
          <div
            className={`py-10 bg-[#f3f1fb]`}
          >
            <div className="xl:container mx-auto px-6">
              <h2 className='font-raleway font-semibold text-2xl sm:text-[1.92rem] leading-7 md:leading-10 mb-1 lg:mb-3 text-DeepKoamaru text-center'>
                ¡Hola! <span className='text-[#8b87d0]'>¿Como puedo ayudarte?</span>
              </h2>
              <p className='font-raleway font-regular text-md sm:text-lg text-DeepKoamaru text-center'>
                Ingresa tu pregunta y con gusto te responderemos.
              </p>

              <div className='flex flex-col items-center justify-center mt-8 gap-6 mx-auto max-w-sm md:max-w-lg w-full'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-3 w-full'>
                  <div className='bg-white rounded-lg py-2 px-3 flex flex-col gap-1'>
                    <label
                      htmlFor="question"
                      className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                    >
                      Pregunta:
                    </label>
                    <input
                      id="question"
                      type="text"
                      name="question"
                      placeholder='Escribe tu pregunta aquí'
                      className='font-raleway font-bold bg-transparent border-0 text-sm text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                      value={state.questionForm?.question || ""}
                      onChange={handleChangeQuestionForm}
                    />
                  </div>
                  <div className='bg-white rounded-lg py-2 px-3 flex flex-col gap-1'>
                    <label
                      htmlFor="email2"
                      className='font-raleway font-regular text-DeepKoamaru text-[0.8rem] leading-4'
                    >
                      Correo electrónico:
                    </label>
                    <input
                      id="email2"
                      type="email"
                      autoComplete="email"
                      name="email"
                      placeholder='email@example.com'
                      className='disable-autofill-white font-raleway font-bold bg-transparent border-0 text-sm text-DeepKoamaru placeholder-gray-300 focus:ring-0 focus:outline-none focus:border-transparent p-0'
                      value={state.questionForm?.email || ""}
                      onChange={handleChangeQuestionForm}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className='font-raleway font-bold inline-flex justify-center bg-[#8b87d0] text-white py-2 px-6 rounded-lg text-center min-w-[140px] -ml-2'
                  onClick={handleSendQuestion}
                >
                  {state.sending_form_pregunta ? <ProgressSpinner strokeWidth='3' style={{ height: 22, width: 22 }} /> : "Enviar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </>
  );
};

const ComunidadContent = memo(({ text, image }) => (
  <div className={`relative rounded-[10px] ${text ? 'bg-[#7672b1]' : ''}`}>
    {image ? (
      <LazyLoadImage
        alt="Comunidad Image"
        effect="opacity"
        src={image}
        className="w-full h-full object-cover rounded-inherit"
        wrapperClassName='flex h-full w-full rounded-inherit'
      />
    ) : null}

    {text && (
      <div className="p-6">
        <p className='font-raleway text-white text-lg leading-6 whitespace-pre-line'>
          {text}
        </p>
      </div>
    )}
  </div>
));

const PromoCard = memo(({ title, description, icon }) => (
  <div className='flex flex-col items-center rounded-lg shadow-[0_1px_1px_rgba(150,150,150,0.2)] w-full'>
    <div className='bg-OrchidHover border-l border-r border-OrchidHover py-2 px-4 rounded-t-inherit w-full'>
      <p className='font-raleway font-bold text-white text-lg text-center'>{title}</p>
    </div>
    <div className='bg-white border-l border-r border-b border-gray-100 flex flex-col gap-6 items-center flex-1 py-4 px-6 rounded-b-inherit w-full'>
      <div className='mt-4'>
        {icon}
      </div>
      <div className='flex flex-1 items-center'>
        <p className='font-raleway text-DeepKoamaru text-center'>{description}</p>
      </div>
    </div>
  </div>
));

const ProfileCard = memo(({ icon, title, description }) => (
  <div className="flex items-center gap-3 px-5 py-2.5 hover:bg-gradient-to-r hover:from-[#ebcde3] hover:to-[#d6c5e1] rounded-xl">
    <div className="h-[58px] w-[58px]">{icon}</div>
    <div className="flex-1">
      <p className="font-raleway font-bold text-lg text-DeepKoamaru">{title}</p>
      <p className="font-raleway text-sm text-DeepKoamaru">{description}</p>
    </div>
  </div>
));

export default Promotores;