import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';

import parseErrorMessage from '../utils/parseErrorMessage';
import { optionsToast } from '../config/toast';
import { getProfile } from '../api/auth';
import { setUser, logout } from '../redux/reducers/authSlice';
import { logout as logoutAdmin } from '../redux/reducers/admin/authSlice';

import ScrollToTop from '../utils/ScrollToTop';

import NoEncontrado from "../pages/Errors/404";
import TerminosCondiciones from '../pages/TerminosCondiciones';
import PoliticaPrivacidad from '../pages/PoliticaPrivacidad';
import PoliticaCookies from '../pages/PoliticaCookies';

import Index from '../pages';
import ProgramasIndex from '../pages/Programas';
import ActualizarSuscripcion from '../pages/InAppPurchase/ActualizarSuscripcion';
import Carrito from '../pages/Carrito';
import Pagado from '../pages/Pagado';
import SobreJime from '../pages/SobreJime';
import BlogInicio from '../pages/Blog';
import BlogSingle from '../pages/BlogSingle';
import Partners from '../pages/Partners';

import ActualizarContraseniaClient from '../pages/Mi-cuenta/ActualizarContrasenia';
import RenovarContraseniaClient from '../pages/Mi-cuenta/RenovarContrasenia';

import MiCuenta from '../pages/Mi-cuenta';
import TuPrograma from '../pages/Mi-cuenta/TuPrograma';
import Cores from '../pages/Mi-cuenta/Cores';
import Core from '../pages/Mi-cuenta/Core';
import Workouts from '../pages/Mi-cuenta/Workouts';
import Workout from '../pages/Mi-cuenta/Workout';
import Partos from '../pages/Mi-cuenta/Partos';
import Parto from '../pages/Mi-cuenta/Parto';
import Descargables from '../pages/Mi-cuenta/Descargables';
import Configuracion from '../pages/Mi-cuenta/Configuracion';
import MisFavoritos from '../pages/Mi-cuenta/MisFavoritos';
import MisRecibos from '../pages/Mi-cuenta/MisRecibos';
import MiSuscripcion from '../pages/Mi-cuenta/MiSuscripcion';
import VideoClase from '../pages/Mi-cuenta/VideoClase';

import AdminHome from '../pages/Admin';
import Login from '../pages/Admin/Login';
import RecuperarCuenta from '../pages/Admin/RecuperarCuenta';
import ActualizarContrasenia from '../pages/Admin/ActualizarContrasenia';

import Perfiles from '../pages/Admin/Perfiles';
import CrearPerfil from '../pages/Admin/Perfiles/CrearPerfil';
import EditarPerfil from '../pages/Admin/Perfiles/EditarPerfil';
import Usuarios from '../pages/Admin/Usuarios';
import CrearUsuario from '../pages/Admin/Usuarios/CrearUsuario';
import EditarUsuario from '../pages/Admin/Usuarios/EditarUsuario';
import Clientas from '../pages/Admin/Clientas';
import CrearClienta from '../pages/Admin/Clientas/CrearClienta';
import EditarClienta from '../pages/Admin/Clientas/EditarClienta';
import Compras from '../pages/Admin/Compras';
import Categorias from '../pages/Admin/Categorias';
import CrearCategoria from '../pages/Admin/Categorias/CrearCategoria';
import CrearSubCategoria from '../pages/Admin/Categorias/CrearSubcategoria';
import EditarCategoria from '../pages/Admin/Categorias/EditarCategoria';
import EditarSubCategoria from '../pages/Admin/Categorias/EditarSubcategoria';
import GaleriaCores from '../pages/Admin/GaleriaCores';
import CrearCore from '../pages/Admin/GaleriaCores/CrearCore';
import EditarCore from '../pages/Admin/GaleriaCores/EditarCore';
import GaleriaWorkouts from '../pages/Admin/GaleriaWorkouts';
import CrearWorkout from '../pages/Admin/GaleriaWorkouts/CrearWorkout';
import EditarWorkout from '../pages/Admin/GaleriaWorkouts/EditarWorkout';
import GaleriaParto from '../pages/Admin/GaleriaParto';
import CrearParto from '../pages/Admin/GaleriaParto/CrearParto';
import EditarParto from '../pages/Admin/GaleriaParto/EditarParto';
import ProgramasAdmin from '../pages/Admin/Programas';
import EditarPrograma from '../pages/Admin/Programas/EditarPrograma';
import Calendarios from '../pages/Admin/Calendarios';
import AsignarWorkout from '../pages/Admin/Calendarios/AsignarWorkout';
import DescargablesAdmin from '../pages/Admin/Descargables';
import CrearDescargable from '../pages/Admin/Descargables/CrearDescargable';
import EditarDescargable from '../pages/Admin/Descargables/EditarDescargable';
import Anuncios from '../pages/Admin/Anuncios';
import CrearAnuncio from '../pages/Admin/Anuncios/CrearAnuncio';
import EditarAnuncio from '../pages/Admin/Anuncios/EditarAnuncio';
import Banners from '../pages/Admin/Banners';
import CrearBanner from '../pages/Admin/Banners/CrearBanner';
import EditarBanner from '../pages/Admin/Banners/EditarBanner';
import Productos from '../pages/Admin/Productos';
import CrearProducto from '../pages/Admin/Productos/CrearProducto';
import EditarProducto from '../pages/Admin/Productos/EditarProducto';
import Cupones from '../pages/Admin/Cupones';
import CrearCupon from '../pages/Admin/Cupones/CrearCupon';
import EditarCupon from '../pages/Admin/Cupones/EditarCupon';
import Blog from '../pages/Admin/Blog';
import CrearEntrada from '../pages/Admin/Blog/CrearEntrada';
import EditarEntrada from '../pages/Admin/Blog/EditarEntrada';
import CalificarVideos from '../pages/Admin/CalificarVideos';
import Secciones from '../pages/Admin/Secciones';
import EditarSeccion from '../pages/Admin/Secciones/EditarSeccion';
import FrasesDia from '../pages/Admin/FrasesDia';
import CrearFrase from '../pages/Admin/FrasesDia/CrearFrase';
import EditarFrase from '../pages/Admin/FrasesDia/EditarFrase';

import primereact from 'primereact/resources/themes/lara-light-indigo/theme.css';
import primereact_min from "primereact/resources/primereact.min.css";
import primereact_icons from 'primeicons/primeicons.css';
import slick from "slick-carousel/slick/slick.css";
import slickTheme from "slick-carousel/slick/slick-theme.css";
import animate from 'animate.css';
import main_styles from '../styles/index.css';
import { useDynamicStyleSheet } from '../hooks/useDynamicStyleSheet';

function RootRouter(props) {
  useDynamicStyleSheet([
    primereact,
    primereact_min,
    primereact_icons,
    slick,
    slickTheme,
    animate,
    main_styles
  ]);

  const toast = React.useRef(null);
  const dispatch = useDispatch();
  // const admin = useSelector((state) => state.admin.auth);
  // const app = useSelector((state) => state.app.auth);
  const {
    app: { auth: app },
    admin: { auth: admin },
  } = useSelector(state => state);
  const { pathLogged, pathAdmin } = useMemo(() => ({
    pathLogged: "/mi-cuenta",
    pathAdmin: "/admin",
  }), []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  React.useLayoutEffect(() => {
    if (app?.user?.id && app?.user?.email) {
      (async () => {
        try {
          const response = await getProfile({ id: app?.user?.id, email: app?.user?.email });
          if (response?.status === true && response?.data) {
            if (response?.data?.User?.ActivitySessions?.length === 0) {
              dispatch(logout());
            } else {
              dispatch(setUser(response?.data));
            }
          }
        } catch (error) {
          const _message = parseErrorMessage(error);
          if (error.response?.status === 401 || error.response?.status === 404) {
            dispatch(logout());
          } else if (_message?.indexOf("La cuenta ha sido desactivada") !== -1) {
            message('Cuenta desactivada', _message);
            dispatch(logout());
          }
        }
      })();
    };
    // eslint-disable-next-line
  }, [app?.user?.id, app?.user?.email]);

  React.useLayoutEffect(() => {
    const _admin = admin?.user?.user;
    if (_admin?.id && _admin?.email) {
      (async () => {
        try {
          await getProfile({ id: _admin?.id, email: _admin?.email }, true);
        } catch (error) {
          if (error.response?.status === 401 || error.response?.status === 404) {
            dispatch(logoutAdmin());
          }
        }
      })();
    };
    // eslint-disable-next-line
  }, [admin?.user?.user?.id, admin?.user?.user?.email]);

  React.useLayoutEffect(() => {
    window.PrimeToast = toast.current || {};
  }, []);

  return (
    <Router>
      <Toast ref={toast} onHide={() => window.PrimeToast?.onHide && window.PrimeToast?.onHide()} className="z-[999]" />
      <ScrollToTop />
      <Routes>
        <Route index exact path="/" element={<Index />} />
        <Route exact path="/terminos-condiciones" element={<TerminosCondiciones />} />
        <Route exact path="/politica-privacidad" element={<PoliticaPrivacidad />} />
        <Route exact path="/politica-cookies" element={<PoliticaCookies />} />
        <Route exact path="/programas/:name?" element={<ProgramasIndex />} />
        <Route exact path="/sobre-jime" element={<SobreJime />} />
        <Route exact path="/blog" element={<BlogInicio />} />
        <Route exact path="/blog/:slug" element={<BlogSingle />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/carrito" element={<Carrito />} />
        <Route exact path="/carrito/:suscripcion?" element={<Carrito />} />
        <Route exact path="/confirmacion/:suscripcion" element={<Pagado />} />
        <Route exact path="/actualizar-suscripcion" element={<ActualizarSuscripcion />} />

        <Route exact path={`/actualizar-contrasenia`} element={<ActualizarContraseniaClient />} />
        <Route exact path={`/renovar-contrasenia`} element={<RenovarContraseniaClient />} />

        <Route exact path={`${pathAdmin}`} element={admin.isAuthenticated ? <AdminHome /> : <Login />} />
        <Route exact path={`${pathAdmin}/login`} element={<Login />} />
        <Route exact path={`${pathAdmin}/recuperar-contrasenia`} element={<RecuperarCuenta />} />
        <Route exact path={`${pathAdmin}/actualizar-contrasenia`} element={<ActualizarContrasenia />} />

        {admin.isAuthenticated && (
          <>
            <Route exact path={`${pathAdmin}/perfiles`} element={<Perfiles />} />
            <Route exact path={`${pathAdmin}/perfiles/crear-perfil`} element={<CrearPerfil />} />
            <Route exact path={`${pathAdmin}/perfiles/editar-perfil/:id`} element={<EditarPerfil isEditar={true} />} />
            <Route exact path={`${pathAdmin}/perfiles/:id`} element={<EditarPerfil />} />
            <Route exact path={`${pathAdmin}/usuarios`} element={<Usuarios />} />
            <Route exact path={`${pathAdmin}/usuarios/crear-usuario`} element={<CrearUsuario />} />
            <Route exact path={`${pathAdmin}/usuarios/editar-usuario/:id`} element={<EditarUsuario isEditar={true} />} />
            <Route exact path={`${pathAdmin}/usuarios/:id`} element={<EditarUsuario />} />
            <Route exact path={`${pathAdmin}/clientas`} element={<Clientas />} />
            <Route exact path={`${pathAdmin}/clientas/crear-clienta`} element={<CrearClienta />} />
            <Route exact path={`${pathAdmin}/clientas/editar-clienta/:id`} element={<EditarClienta isEditar={true} />} />
            <Route exact path={`${pathAdmin}/clientas/:id`} element={<EditarClienta />} />
            <Route exact path={`${pathAdmin}/compras`} element={<Compras />} />
            <Route exact path={`${pathAdmin}/categorias`} element={<Categorias />} />
            <Route exact path={`${pathAdmin}/categorias/crear-categoria`} element={<CrearCategoria />} />
            <Route exact path={`${pathAdmin}/categorias/crear-subcategoria`} element={<CrearSubCategoria />} />
            <Route exact path={`${pathAdmin}/categorias/editar-categoria/:id`} element={<EditarCategoria isEditar={true} />} />
            <Route exact path={`${pathAdmin}/categorias/editar-subcategorias`} element={<EditarSubCategoria isEditar={true} />} />
            <Route exact path={`${pathAdmin}/calificaciones-videos`} element={<CalificarVideos />} />
            <Route exact path={`${pathAdmin}/secciones`} element={<Secciones />} />
            <Route exact path={`${pathAdmin}/secciones/editar-seccion/:id`} element={<EditarSeccion isEditar={true} />} />
            <Route exact path={`${pathAdmin}/frases`} element={<FrasesDia />} />
            <Route exact path={`${pathAdmin}/frases/crear-frase`} element={<CrearFrase />} />
            <Route exact path={`${pathAdmin}/frases/:id`} element={<EditarFrase />} />
            <Route exact path={`${pathAdmin}/frases/editar-frase/:id`} element={<EditarFrase isEditar={true} />} />
            <Route exact path={`${pathAdmin}/galeria-cores`} element={<GaleriaCores />} />
            <Route exact path={`${pathAdmin}/galeria-cores/crear-core`} element={<CrearCore />} />
            <Route exact path={`${pathAdmin}/galeria-cores/editar-core/:id`} element={<EditarCore isEditar={true} />} />
            <Route exact path={`${pathAdmin}/galeria-cores/:id`} element={<EditarCore />} />
            <Route exact path={`${pathAdmin}/galeria-workouts`} element={<GaleriaWorkouts />} />
            <Route exact path={`${pathAdmin}/galeria-workouts/crear-workout`} element={<CrearWorkout />} />
            <Route exact path={`${pathAdmin}/galeria-workouts/editar-workout/:id`} element={<EditarWorkout isEditar={true} />} />
            <Route exact path={`${pathAdmin}/galeria-workouts/:id`} element={<EditarWorkout />} />
            <Route exact path={`${pathAdmin}/galeria-parto`} element={<GaleriaParto />} />
            <Route exact path={`${pathAdmin}/galeria-parto/crear-video`} element={<CrearParto />} />
            <Route exact path={`${pathAdmin}/galeria-parto/editar-video/:id`} element={<EditarParto isEditar={true} />} />
            <Route exact path={`${pathAdmin}/galeria-parto/:id`} element={<EditarParto />} />
            <Route exact path={`${pathAdmin}/programas`} element={<ProgramasAdmin />} />
            <Route exact path={`${pathAdmin}/programas/editar-programa/:id`} element={<EditarPrograma isEditar={true} />} />
            <Route exact path={`${pathAdmin}/calendarios`} element={<Calendarios />} />
            <Route exact path={`${pathAdmin}/calendarios/editar-calendario?/:name`} element={<Calendarios />} />
            <Route exact path={`${pathAdmin}/calendarios/asignar-workout`} element={<AsignarWorkout />} />
            <Route exact path={`${pathAdmin}/descargables`} element={<DescargablesAdmin />} />
            <Route exact path={`${pathAdmin}/descargables/crear-descargable`} element={<CrearDescargable />} />
            <Route exact path={`${pathAdmin}/descargables/editar-descargable/:id`} element={<EditarDescargable isEditar={true} />} />
            <Route exact path={`${pathAdmin}/descargables/:id`} element={<EditarDescargable />} />
            <Route exact path={`${pathAdmin}/anuncios`} element={<Anuncios />} />
            <Route exact path={`${pathAdmin}/anuncios/crear-anuncio`} element={<CrearAnuncio />} />
            <Route exact path={`${pathAdmin}/anuncios/editar-anuncio/:id`} element={<EditarAnuncio isEditar={true} />} />
            <Route exact path={`${pathAdmin}/anuncios/:id`} element={<EditarAnuncio />} />
            <Route exact path={`${pathAdmin}/banners`} element={<Banners />} />
            <Route exact path={`${pathAdmin}/banners/crear-banner`} element={<CrearBanner />} />
            <Route exact path={`${pathAdmin}/banners/editar-banner/:id`} element={<EditarBanner isEditar={true} />} />
            <Route exact path={`${pathAdmin}/banners/:id`} element={<EditarBanner />} />
            <Route exact path={`${pathAdmin}/productos`} element={<Productos />} />
            <Route exact path={`${pathAdmin}/productos/crear-producto`} element={<CrearProducto />} />
            <Route exact path={`${pathAdmin}/productos/editar-producto/:id`} element={<EditarProducto isEditar={true} />} />
            <Route exact path={`${pathAdmin}/productos/:id`} element={<EditarProducto />} />
            <Route exact path={`${pathAdmin}/cupones`} element={<Cupones />} />
            <Route exact path={`${pathAdmin}/cupones/crear-cupon`} element={<CrearCupon />} />
            <Route exact path={`${pathAdmin}/cupones/editar-cupon/:id`} element={<EditarCupon isEditar={true} />} />
            <Route exact path={`${pathAdmin}/cupones/:id`} element={<EditarCupon />} />
            <Route exact path={`${pathAdmin}/blog`} element={<Blog />} />
            <Route exact path={`${pathAdmin}/blog/crear-entrada`} element={<CrearEntrada />} />
            <Route exact path={`${pathAdmin}/blog/editar-entrada/:id`} element={<EditarEntrada isEditar={true} />} />
            <Route exact path={`${pathAdmin}/blog/:id`} element={<EditarEntrada />} />
          </>
        )}

        {app.isAuthenticated ? (
          <>
            <Route exact path={`${pathLogged}`} element={<MiCuenta />} />
            <Route exact path={`${pathLogged}/tu-programa`} element={<TuPrograma />} />
            <Route exact path={`${pathLogged}/tu-programa/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/cores`} element={<Cores />} />
            <Route exact path={`${pathLogged}/cores/category/:id_category`} element={<Cores isCategory={true} />} />
            <Route exact path={`${pathLogged}/cores/only_category/:id_category`} element={<Core isOnlyCategory={true} />} />
            <Route exact path={`${pathLogged}/cores/only_category/:id_category/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/cores/category/:id_category/subcategory/:id_subcategory`} element={<Core />} />
            <Route exact path={`${pathLogged}/cores/category/:id_category/subcategory/:id_subcategory/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/workouts`} element={<Workouts />} />
            <Route exact path={`${pathLogged}/workouts/category/:id_category`} element={<Workouts isCategory={true} />} />
            <Route exact path={`${pathLogged}/workouts/only_category/:id_category`} element={<Workout isOnlyCategory={true} />} />
            <Route exact path={`${pathLogged}/workouts/only_category/:id_category/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/workouts/category/:id_category/subcategory/:id_subcategory`} element={<Workout />} />
            <Route exact path={`${pathLogged}/workouts/category/:id_category/subcategory/:id_subcategory/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/parto`} element={<Partos />} />
            <Route exact path={`${pathLogged}/parto/category/:id_category`} element={<Partos isCategory={true} />} />
            <Route exact path={`${pathLogged}/parto/only_category/:id_category`} element={<Parto isOnlyCategory={true} />} />
            <Route exact path={`${pathLogged}/parto/only_category/:id_category/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/parto/category/:id_category/subcategory/:id_subcategory`} element={<Parto />} />
            <Route exact path={`${pathLogged}/parto/category/:id_category/subcategory/:id_subcategory/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/descargables`} element={<Descargables />} />
            <Route exact path={`${pathLogged}/configuracion`} element={<Configuracion />} />
            <Route exact path={`${pathLogged}/mis-favoritos`} element={<MisFavoritos />} />
            <Route exact path={`${pathLogged}/mis-favoritos/:type/:id`} element={<VideoClase />} />
            <Route exact path={`${pathLogged}/mis-recibos`} element={<MisRecibos />} />
            <Route exact path={`${pathLogged}/mi-suscripcion`} element={<MiSuscripcion />} />
          </>
        ) : (
          <>
            <Route
              path="/mi-cuenta/*"
              element={<Navigate to="/" replace />}
            />
          </>
        )}

        <Route path="*" element={<NoEncontrado />} />
      </Routes>
    </Router>
  );
}

export default React.memo(RootRouter);