import { Fragment, memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getContenidoClases } from "../../misc/getContenidoClases";

const ClaseCard = memo(({ nombre, imagen, imagen_completa }) => (
  <div className={`relative bg-Magnolia rounded-xl ${!imagen_completa ? "h-[140px] w-[120px]" : "h-[144px] w-[130px]"}`}>
    {imagen_completa ? (
      <LazyLoadImage
        alt={`${nombre} thumbnail`}
        className="w-full h-full object-cover mx-auto select-none pointer-events-none rounded-inherit"
        src={imagen_completa}
      />
    ) : (
      <Fragment>
        <LazyLoadImage
          alt={`${nombre} thumbnail`}
          className="w-full h-full object-cover mx-auto select-none pointer-events-none rounded-inherit"
          src={imagen}
        />
        <div className="absolute bottom-0 rounded-inherit left-0 w-full">
          <div className="h-[70px] px-1 flex items-center justify-center rounded-inherit bg-gradient-to-t from-Crayola/100 via-Crayola/80 to-transparent">
            <p className="font-golos-semibold text-[12px] text-white text-center">{nombre}</p>
          </div>
        </div>
      </Fragment>
    )}
  </div>
));

const ContenidoClases = () => {
  const clases = getContenidoClases();

  return (
    <div className="bg-white py-16 w-full">
      <div className="px-6 flex flex-wrap flex-col overflow-x-auto no-scrollbar">
        <div className="flex flex-row justify-center items-center gap-5">
          {clases.map((clase) => (
            <ClaseCard key={clase.nombre} {...clase} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ContenidoClases);