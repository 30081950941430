import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import { ReactComponent as IconoIr } from "../../assets/icons/flecha-icon.svg";
import { ReactComponent as IconoBack } from "../../assets/icons/flechaback-icon.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import { useCustomState } from '../../hooks/useCustomState';
import Loading from '../../components/Loading';
import { getCategoriesWorkouts, getSubCategoriesWorkouts } from '../../api/client/workouts';
import { getSecciones } from '../../api/client/secciones';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { optionsToast } from '../../config/toast';
import { ButtonPrimary } from '../../components/Button';
import { isEmpty } from '../../utils';

const Workouts = ({ isCategory }) => {
  const { id_category } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    workouts: [],
    seccion: null,
  });
  const titlePage = isCategory && (` / ${state?.workouts?.[0]?.Category?.name || ""}`);
  const descriptionCategory = isCategory && state?.workouts?.[0]?.Category?.description;

  useEffect(() => {
    loadData(isCategory, id_category);

    // eslint-disable-next-line
  }, [isCategory, id_category]);

  const loadData = useCallback(async (isCategory, id_category) => {
    if (isEmpty(user?.programID)) return;

    try {
      setState({ loading: true });
      const _workouts = isCategory === true ? await getSubCategoriesWorkouts(id_category, { programID: user?.programID }) : await getCategoriesWorkouts({ programID: user?.programID });
      const _secciones = await getSecciones({ code: "workouts" });

      setState({
        workouts: _workouts?.data,
        seccion: _secciones?.status === true && _secciones?.data?.[0],
        loading: false,
      });
    } catch (error) {
      setState({ workouts: [], loading: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, [user]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  return (
    <>
      <SEO
        title={`Workouts ${titlePage || ""}`}
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />

      <div className='bg-Magnolia h-full pb-4'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            {(user?.subscription?.isActive !== true) ? (
              <div className='flex flex-col items-center justify-center gap-6'>
                <div className='bg-white px-4 py-6 rounded-xl w-full'>
                  <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
                  <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
                </div>
              </div>
            ) : (
              <>
                {state.loading ? (
                  <Loading containerClassName="h-full" />
                ) : (
                  <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                      <div className='flex flex-col text-center md:text-left'>
                        <p className='font-golos-semibold text-Crayola text-2xl'>Workouts {titlePage || ""}</p>
                        <p>{descriptionCategory || (!isEmpty(state?.seccion?.description) && state?.seccion?.description) || "Explora y encuentra fácilmente todos los workouts del programa."}</p>
                      </div>
                      {state.loading ? (
                        <Loading />
                      ) : (state.workouts?.length === 0) ? (
                        <p className='font-golos-semibold text-red-600 text-center text-lg'>No se encontraron resultados.</p>
                      ) : (
                        <>
                          {state.workouts?.length > 0 ? (
                            <>
                              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
                                {state.workouts?.map((item, index) => (
                                  <Link
                                    key={`workout-key-${item?.id || index}`}
                                    to={isCategory ? `subcategory/${item?.id}` : (item?.SubCategories?.length === 0 ? `only_category/${item?.id}` : `category/${item?.id}`)}
                                    className='c-pointer w-full'
                                  >
                                    <div className="flex flex-col w-full">
                                      <Image
                                        src={item?.image}
                                        imageClassName="w-full object-cover object-top h-full rounded-t-xl"
                                        className='bg-Magnolia min-h-[160px] h-[160px] rounded-t-xl'
                                        onError={(event) => {
                                          event.target.onerror = null; // previene un bucle infinito
                                          event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                                        }}
                                      />
                                      <div className='bg-Crayola pl-6 py-3 flex items-center space-x-2 -mt-[2px] rounded-b-xl'>
                                        <div className='flex-1'>
                                          <p
                                            className='font-golos-semibold text-white'
                                            style={{
                                              display: "-webkit-box",
                                              WebkitLineClamp: 1,
                                              WebkitBoxOrient: "vertical",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {item?.name}
                                          </p>
                                          {isCategory && (<p className='text-white text-sm mb-1'>{item?.count_workouts || "0"} rutinas</p>)}
                                        </div>
                                        <div className='flex justify-center items-center h-full'>
                                          <div className='inline-flex h-full pl-3 pr-4'>
                                            <IconoIr fill={"#fff"} width={18} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                              {isCategory && (
                                <div className='flex items-center justify-center pt-10 pb-6'>
                                  <ButtonPrimary
                                    text={
                                      <div className='flex items-center'>
                                        <span className="d-block mr-3">
                                          <IconoBack fill={"#fff"} width={18} />
                                        </span>
                                        <p className='font-golos'>Volver <span className='font-golos-semibold'>a todos los Workouts</span></p>
                                      </div>
                                    }
                                    withIcon={false}
                                    classNameContainer="border-DeepKoamaru"
                                    classNameButton={"!bg-DeepKoamaru hover:!bg-[#1e114b] py-3 px-6"}
                                    onClick={() => navigate("/mi-cuenta/workouts")}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <div className='flex flex-col justify-center text-center'>
                              <div className='font-golos-medium text-Crayola'>
                                <p>No se encontraron resultados, inténtalo de nuevo más tarde.</p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Workouts;