import { useMemo, useCallback } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BenefitsGrid = ({ benefits, program }) => {
  const styles = useMemo(() => {
    return {
      embarazo: {
        container: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-5xl mx-auto",
        rows: [],
        card: "bg-Magnolia px-6",
        textColor: "text-DarkJungleGreen",
        defaultPaddingY: "py-4",
      },
      posparto: {
        container: "grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-2xl mx-auto",
        rows: [], // No se divide en filas
        card: "bg-LavenderBlush px-8",
        textColor: "text-DeepKoamaru",
        defaultPaddingY: "py-6",
      },
      strength: {
        container: "grid gap-4 max-w-3xl mx-auto",
        rows: [
          { className: "grid grid-cols-1 sm:grid-cols-2 gap-4", count: 2 }, // Primera fila con 2 elementos
          { className: "grid grid-cols-1 sm:grid-cols-3 gap-4", count: 3 }, // Segunda fila con 3 elementos
        ],
        card: "bg-Magnolia px-6",
        textColor: "text-DarkJungleGreen sm:whitespace-break-spaces",
        defaultPaddingY: "py-8",
      },
    }[program] || {};
  }, [program]);

  const renderBenefit = useCallback(
    (benefit, index) => (
      <div
        key={index}
        className={`flex flex-row items-center gap-4 rounded-2xl ${styles.card} ${benefit.paddingY || styles.defaultPaddingY}`}
      >
        <LazyLoadImage
          alt="icon"
          src={benefit.icon}
          className={benefit.imageClass || "w-11 h-11"}
          wrapperClassName={benefit.wrapperClass || "w-[36px]"}
        />
        <p className={`flex-1 font-golos leading-[1.05rem] ${styles.textColor}`}>
          {benefit.text}
        </p>
      </div>
    ),
    [styles]
  );

  return (
    <div className={styles.container}>
      {styles.rows?.length > 0 ? (
        styles.rows.map((row, rowIndex) => (
          <div key={rowIndex} className={row.className}>
            {benefits
              .slice(
                styles.rows.slice(0, rowIndex).reduce((acc, r) => acc + r.count, 0), // Calcula el inicio del slice
                styles.rows.slice(0, rowIndex + 1).reduce((acc, r) => acc + r.count, 0) // Calcula el final del slice
              )
              .map(renderBenefit)}
          </div>
        ))
      ) : (
        benefits.map(renderBenefit)
      )}
    </div>
  );
};

export default BenefitsGrid;