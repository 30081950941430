import React from "react";
import { Image } from "primereact/image";

const CarouselTestimoniosTemplate = (item, opts = null) => {
  return (
    <div className="bg-Magnolia text-DarkJungleGreen rounded-xl mx-2">
      <div className="md:max-w-sm px-4 py-4">
        {item?.name && (
          <div className="flex items-center justify-between mb-3">
            <h4 className="font-bold text-md text-Crayola">{item?.name}</h4>
            <div className="bg-Crayola w-7 h-7 rounded-full" />
          </div>
        )}
        {item?.description && (
          <p className="font-medium text-sm mt-0 mb-0">{item?.description}</p>
        )}
        {item?.description_img && (
          <Image
            src={item?.description_img}
            alt="Testimonio"
            className="flex justify-center select-none rounded-lg"
            imageClassName={`rounded-lg object-contain ${opts?.heightClassName || "h-[13rem]"}`}
            preview
            height={208}
            width={258}
          />
        )}
        {(opts?.withImage && item?.image) && (
          <div className="px-2 mt-4">
            <Image
              src={item?.image}
              alt="Portada de testimonio"
              className="flex justify-center select-none rounded-lg pointer-events-none"
              imageClassName={`rounded-lg object-contain ${opts?.heightClassName || "h-[13rem]"}`}
              height={208}
              width={258}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CarouselTestimoniosTemplate;