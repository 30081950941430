export const getContenidoClases = () => {
  return [
    {
      nombre: "Fuerza",
      // imagen: require("../assets/images/contenido_clases/fuerza.jpg"),
      imagen_completa: require("../assets/images/contenido_clases/img-wkt01.png")
    },
    {
      nombre: "Cardio",
      // imagen: require("../assets/images/contenido_clases/cardio.jpg"),
      imagen_completa: require("../assets/images/contenido_clases/img-wkt02.png")
    },
    {
      nombre: "Ejercicios de Preparación Para el parto",
      // imagen: require("../assets/images/contenido_clases/preparacion_parto.webp"),
      imagen_completa: require("../assets/images/contenido_clases/img-wkt03.png")
    },
    {
      nombre: "Ejercicios de Rehabilitación Después Del parto",
      // imagen: require("../assets/images/contenido_clases/preparacion_parto.webp"),
      imagen_completa: require("../assets/images/contenido_clases/img-wkt04.png")
    },
    {
      nombre: "Pilates, yoga Y barre",
      // imagen: require("../assets/images/contenido_clases/preparacion_parto.webp"),
      imagen_completa: require("../assets/images/contenido_clases/img-wkt05.png")
    },
    {
      nombre: "Ejercicio para Embarazo En reposo",
      // imagen: require("../assets/images/contenido_clases/preparacion_parto.webp"),
      imagen_completa: require("../assets/images/contenido_clases/img-wkt06.png")
    },
    {
      nombre: "Retos",
      // imagen: require("../assets/images/contenido_clases/preparacion_parto.webp"),
      imagen_completa: require("../assets/images/contenido_clases/img-wkt07.png")
    },
  ];
};