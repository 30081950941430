import {
  memo,
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  Fragment
} from "react";
import Slider from "react-slick";
import { Image } from "primereact/image";
import { getBeneficios } from "../../misc/getBeneficios";

const BeneficiosTemplateItem = memo(({ item }) => (
  <div className="h-full bg-Magnolia2 rounded-md text-center flex flex-col items-center justify-center gap-8 px-5 py-8">
    <Image src={item.icon} alt="Icono" height={43} width={46} imageStyle={{ width: 46 }} />
    <p className="text-sm text-DarkJungleGreen mb-0">{item.text}</p>
  </div>
));

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: false,
  autoplay: false,
  speed: 500,
  adaptiveHeight: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  cssEase: "linear",
  /* Esto es solo para cuando usa slidesToShow: 3.2 */
  // className: "center",
  // centerMode: true,
  // centerPadding: "26px",
  /* Fin Center */
  responsive: [
    { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 2 } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

const BeneficiosCarousel = forwardRef(({ customRef, ...props }, forwardedRef) => {
  const [data, setData] = useState([]);
  const sliderRef = useRef(null);

  const loadData = useCallback(async () => {
    try {
      const response = getBeneficios();
      setData(response);
    } catch {
      setData([]);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useImperativeHandle(forwardedRef, () => ({
    next: () => (props?.customRef || sliderRef).current?.slickNext(),
    previous: () => (props?.customRef || sliderRef).current?.slickPrev(),
  }), [props?.customRef]);

  return (
    <div className="slider-container slider-flex-height slick-gap -ml-[1em]">
      {data.length > 0 ? (
        <Slider ref={customRef ?? sliderRef} {...sliderSettings}>
          {data.map((item, index) => (
            <BeneficiosTemplateItem key={`item-key-${index}`} item={item} />
          ))}
        </Slider>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
});

export default memo(BeneficiosCarousel);