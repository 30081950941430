import { memo, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import CarouselTestimoniosTemplate from "./CarouselTestimoniosTemplate";
import { useCustomState } from "../../hooks/useCustomState";
import { responsiveOptionsCarousel } from "../../utils/responsiveOptionsCarousel";
import {
  getCarouselTestimoniosEmbarazoItems,
  // getCarouselTestimoniosPospartoItems,
  // getCarouselTestimoniosStrengthItems
} from "../../misc/getCarouselItems";

const TestimoniosClientas = () => {
  const [state, setState] = useCustomState({
    carouselTestimoniosItems: [],
  });

  useEffect(() => {
    setState({
      carouselTestimoniosItems: [
        ...getCarouselTestimoniosEmbarazoItems(),
        // ...getCarouselTestimoniosPospartoItems(),
        // ...getCarouselTestimoniosStrengthItems(),
      ],
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className='py-16 bg-[#f5def0]'>
      <div className="xl:container mx-auto px-6">
        <Carousel
          value={state.carouselTestimoniosItems}
          numVisible={4}
          numScroll={4}
          responsiveOptions={responsiveOptionsCarousel}
          className="custom-carousel pagination-indicators-gray"
          circular
          showNavigators={false}
          showIndicators={false}
          autoplayInterval={7000}
          itemTemplate={CarouselTestimoniosTemplate}
        />
      </div>
    </div>
  );
}

export default memo(TestimoniosClientas);