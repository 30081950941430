import React, { useState, useMemo } from "react";
import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactComponent as PlayIcon } from "../assets/icons/btn-play01.svg";

/**
 * VideoPreviewPlayer - Componente para mostrar una previsualización de video con un ícono de Play.
 * Al hacer clic en la imagen, el video comienza a reproducirse automáticamente si `controls` es `false`.
 * También puede iniciarse en `autoplay` si la prop `autoplay` está habilitada.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.thumbnail - URL de la imagen de previsualización.
 * @param {string} props.videoSrc - URL del video a reproducir.
 * @param {string} props.altText - Texto alternativo para la imagen de previsualización.
 * @param {boolean} [props.hasOverlay=true] - Indica si la imagen debe tener una capa oscura.
 * @param {string} [props.playerContainerClassName] - Clases CSS personalizadas para el contenedor del player.
 * @param {boolean} [props.autoplay=false] - Si es `true`, el video se reproduce automáticamente sin hacer clic.
 * @param {boolean} [props.loop=false] - Si es `true`, el video se repetirá en bucle.
 * @param {boolean} [props.controls=true] - Si es `true`, muestra los controles del reproductor. 
 * 
 * @returns {JSX.Element} Componente de previsualización de video.
 */
const VideoPreviewPlayer = ({
  thumbnail,
  videoSrc,
  altText,
  hasOverlay = true,
  playerContainerClassName,
  autoplay = false,
  loop = false,
  controls = true,
}) => {
  const [isPlaying, setIsPlaying] = useState(autoplay);

  const containerClassName = useMemo(() => {
    return playerContainerClassName
      ? `player-container ${playerContainerClassName}`
      : "bg-[#212121] player-container rounded-2xl";
  }, [playerContainerClassName]);

  return (
    <div className={containerClassName}>
      <div className="player-wrapper-responsive">
        {isPlaying ? (
          <ReactPlayer
            className="player-wrapper-video"
            url={videoSrc}
            playing
            muted
            playsinline
            loop={loop}
            controls={controls}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          />
        ) : (
          <div
            className="player-thumbnail-wrapper c-pointer"
            onClick={() => setIsPlaying(true)}
          >
            <LazyLoadImage
              src={thumbnail}
              alt={altText}
              className="player-thumbnail"
              effect="opacity"
            />
            {hasOverlay && <div className="thumbnail-overlay"></div>}
            <div className="player-play-icon c-pointer">
              <PlayIcon height={64} width={64} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPreviewPlayer;